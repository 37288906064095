import React, { useEffect, useState } from "react";
import DropdownFilter from "../../../Atoms/Inputs/DropdownFields/A_DropdownFilter";
import SearchInputField from "../../../Atoms/Inputs/SearchInputField/A_SearchInputField";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchApiData,
  fetchApiSelectedValue,
  fetchFilterData,
} from "../../../../../store/actions/proposalTemplateActions";
import { bindActionCreators } from "redux";
import * as toastActions from "../../../../../store/actions/toastActions";
import { propTypes } from "react-addons-css-transition-group";
import M_ManageEntityForm from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm";
import { resetManagePage } from "../../../../../store/actions/managePageActions";
import Comment from "../../../Atoms/Inputs/DropdownFields/Comment";
const ProposalTracker = (props) => {
  const chart = props && props.content && props.content.chart;
  const SPNamePortfolio = chart.spNamePortfolio ? chart.spNamePortfolio : "";
  const SPNameBorrower = chart.spNameBorrower ? chart.spNameBorrower : "";
  const Query = chart.query ? chart.query : "";
  const sqlAPI = chart.sqlAPI ? chart.sqlAPI : false;
  const crmAPI = chart.crmAPI ? chart.crmAPI : false;
  const fieldName = chart.fieldName ? chart.fieldName : "";
  const pageId = chart.pageId ? chart.pageId : "";
  const templateId = chart.templateId ? chart.templateId : "";
  const pageName = chart.pageName ? chart.pageName : "";
  const snackBarContent = chart.snackBarMessage ? chart.snackBarMessage : "";
  const btnLabel1 = chart.btnLabel1;
  const btnLabel2 = chart.btnLabel2;
  const btnLabel3 = chart.btnLabel3;
  const btnLabel4 = chart.btnLabel4;
  const addBtnTitle = chart.addBtnTitle
    ? chart.addBtnTitle
    : "CREATE NEW PROPOSAL";
  const [openForm, setOpenForm] = useState(false);
  const [entity, setEntity] = useState({});
  const filterDropdownLabel = chart.filterDropdownLabel
    ? chart.filterDropdownLabel
    : "Proposal";
  const filterOptionLabel = chart.filterOptionLabel
    ? chart.filterOptionLabel
    : "Borrower";
  const [borrowertoggle, setBorrowerToggle] = useState(false);
  const [selectedDropData, setSelectedDropData] = useState("");
  const [selectedBorData, setSelectedBorData] = useState("");
  const [addtoggle, setAddToggle] = useState(false);
  const [dropdowndata, setDropdownData] = useState(
    props && props.sqlApiData && props.sqlApiData.res
  );
  const prevEntity = useSelector((state) => state.previousEntity.prevEntity);
  const [borrowerData, setBorrowerData] = useState(
    props && props.borrowerData && props.borrowerData.res
      ? props.borrowerData.res
      : []
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setDropdownData(props && props.sqlApiData && props.sqlApiData.res);
  }, [props]);
  // Fetching API for Proposal
  useEffect(() => {
    const query = Query;
    const spNamePortfolio = SPNamePortfolio;
    const userEmail = props.user && props.user.email;
    if (sqlAPI == true) {
      dispatch(fetchApiData(query, spNamePortfolio, userEmail));
    }
  }, [dispatch]);

  // Fetching Borrower Data with respect to Proposal Dropdown Value
  useEffect(() => {
    if (selectedDropData.length > 0) {
      const query = Query;
      const spNameBorrower = SPNameBorrower;
      dispatch(fetchApiSelectedValue(query, spNameBorrower, selectedDropData));
    }
  }, [selectedDropData]);

  useEffect(() => {
    if (selectedDropData.length > 0 && selectedBorData.length > 0) {
      dispatch(fetchFilterData(selectedDropData, selectedBorData));
    }
  }, [selectedDropData, selectedBorData]);
  const handleAddButton = () => {
    if (prevEntity && prevEntity.templateId !== templateId) {
      dispatch(resetManagePage());
    }
    setOpenForm(true);
  };

  useEffect(() => {
    setEntity({
      pageName: pageName,
      recordId: "",
      pageId: pageId,
      templateId: templateId,
    });
  }, []);

  return (
    <div>
      <div>
        <div style={{ display: "flex", marginRight: 10 }}>
          <div style={{ marginRight: 25 }}>
            <DropdownFilter
              setBorrowerToggle={setBorrowerToggle}
              dropdowndata={dropdowndata}
              selectedDropData={selectedDropData}
              setSelectedDropData={setSelectedDropData}
              filterDropdownLabel={filterDropdownLabel}
            />
          </div>
          <SearchInputField
            borrowerToggle={borrowertoggle}
            setAddToggle={setAddToggle}
            borrowerData={borrowerData}
            filterOptionLabel={filterOptionLabel}
            setSelectedBorData={setSelectedBorData}
          />
        </div>
        {addtoggle && (
          <Button
            style={{ float: "right", bottom: 50, right: 50 }}
            variant="contained"
            color="primary"
            onClick={handleAddButton}
          >
            {addBtnTitle}
          </Button>
        )}
        {/* <Comment /> */}
      </div>
      {openForm && (
        <M_ManageEntityForm
          isEditable={false}
          entity={entity}
          isFormUploadFileShare={true}
          open={openForm}
          refresh={false}
          fieldName={fieldName}
          snackBarContent={snackBarContent}
          onClose={() => {
            setOpenForm(false);
          }}
          // IRCSlotPayloadCallback={getIrcDetails}
          // IRCSlotCardType={mettingSlotEnums.IRCSlotCardType}
        />
      )}
    </div>
  );
};
ProposalTracker.propTypes = {
  sqlApiData: propTypes.any,
  borrowerData: propTypes.any,
  content: propTypes.any,
  user: propTypes.any,
};

function mapStateToProps(state) {
  return {
    sqlApiData: state.proposalTrackerReducer.apiData,
    borrowerData: state.proposalTrackerReducer.borrowerData,
    user: state.oidc.user.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, toastActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ProposalTracker);
