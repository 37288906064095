import React, { useState, useEffect } from "react";
import {
  withStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Tabs,
  Tab,
  AppBar,
  TablePagination,
} from "@material-ui/core";
import PropTypes from "prop-types";
import A_Divider from "../../../../Atoms/DataDisplay/Dividers/A_Divider";
import A_SimpleDialog from "../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import * as common from "../../../../../../Utils/Helpers/common";
import KtCodesMainForm from "./KtCodesMainForm";
import { getDataFromSQLSp } from "../../../../Molecules/Common/File Upload/M_fileUpload.helper";
import Skeleton from "@material-ui/lab/Skeleton";
import { connect } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import { message } from "../../../../../../Utils/config/messages";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";
import A_CircularIndeternment from "../../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import A_Select from "../../../../Atoms/Inputs/Selects/A_Select";
import { bindActionCreators } from "redux";
import * as toastActions from "../../../../../../store/actions/toastActions";
import * as XLSX from "xlsx";
import GetAppIcon from "@material-ui/icons/GetApp";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "200px",
  },
  container: {
    marginTop: theme.spacing(2),
  },
  addButton: {
    color: "rgb(25, 118, 210)",
  },
  tabHeader: {
    backgroundColor: "#d3d3d3",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const KtCodesMain = (props) => {
  const classes = useStyles();
  const Pagination =
    props.content && props.content.Pagination ? props.content.Pagination : 25;
  const NPV_Key =
    props.content && props.content.NPV_Key
      ? props.content.NPV_Key
      : "NPV Impact";
  const title = props.content && props.content.title ? props.content.title : "";
  const { chartTheme } = common.getTheme() ? common.getTheme() : {};
  const { TableChart } = chartTheme ? chartTheme : {};
  const { style } = TableChart && TableChart.header ? TableChart.header : {};
  const order = "asc";
  const orderBy = "";
  const [getCategory, setGetCategory] = useState([]);
  const [matched, setMatched] = useState([]);
  const [unMatched, setUnMatched] = useState([]);
  const [orgMatched, setOrgMatched] = useState([]);
  const [orgUnMatched, setOrgtUnMatched] = useState([]);
  const [isFormVisible, setFormVisible] = useState(false);
  const [bool, setBool] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(Pagination);
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [uniqueAssets, setUniqueAssets] = useState([]);
  const [uniqueRegionCode, setUniqueRegionCode] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState("");
  const [selectedRegionCode, setSelectedRegionCode] = useState("");
  const [toolbarFilterValue, setToolbarFilterValue] = useState("");

  const [formData, setFormData] = useState({
    Code: "",
    label: "",
    "Change Details": "",
    "Change Title": "",
    "Reason For Change": "",
    Category1: "",
    Category2: "",
    Category3: "",
    Asset: "",
    NPV: "",
  });
  const [Icon, setIcon] = React.useState({
    Asset: "down",
    "Change Details": "down",
    "Change Title": "down",
    "Reason For Change": "down",
    Category1: "down",
    Category2: "down",
    Category3: "down",
    Code: "down",
    NPV: "down",
  });

  const handleDownload = () => {
    const workbook = XLSX.utils.book_new();

    let matchedDataExcel = JSON.parse(JSON.stringify(matched));
    let unMatchedDataExcel = JSON.parse(JSON.stringify(unMatched));

    if (matchedDataExcel && matchedDataExcel.length > 0) {
      for (let i = 0; i < matchedDataExcel.length; i++) {
        delete matchedDataExcel[i].RegionCode;
        delete matchedDataExcel[i].PBI_ID;
        delete matchedDataExcel[i].Mapping;
        matchedDataExcel[i][NPV_Key] = formatValue(matchedDataExcel[i].NPV);
        delete matchedDataExcel[i].NPV;
      }
      const matchedWorksheet = XLSX.utils.json_to_sheet(matchedDataExcel);
      XLSX.utils.book_append_sheet(workbook, matchedWorksheet, `Matched`);
    }

    if (unMatchedDataExcel && unMatchedDataExcel.length > 0) {
      for (let i = 0; i < unMatchedDataExcel.length; i++) {
        delete unMatchedDataExcel[i].RegionCode;
        delete unMatchedDataExcel[i].PBI_ID;
        delete unMatchedDataExcel[i].Mapping;
        unMatchedDataExcel[i][NPV_Key] = formatValue(unMatchedDataExcel[i].NPV);
        delete unMatchedDataExcel[i].NPV;
      }
      const unMatchedWorksheet = XLSX.utils.json_to_sheet(unMatchedDataExcel);
      XLSX.utils.book_append_sheet(workbook, unMatchedWorksheet, `Unmatched`);
    }
    XLSX.writeFile(workbook, `${title}.xlsx`);
  };

  const getTableData = async (date) => {
    setLoading(true);
    // setSelectedRegionCode("");
    // setSelectedAsset("");
    try {
      let query = "res";
      let response = await getDataFromSQLSp({
        chartSPs: true,
        spDetails: [
          {
            id: query,
            spName: "[JlChart].[Usp_MappingKTCode]",
            parameters: { Quarter: date },
          },
        ],
      });
      //  setRows(response.res);
      let MATCHED = response.res.filter((item) => item.Mapping === "MATCHED");
      let UNMATCHED = response.res.filter(
        (item) => item.Mapping === "UNMATCHED"
      );
      let copyMatched = [];
      let copyUnMatched = [];
      if (selectedAsset) {
        copyMatched = MATCHED.filter((item) => item.Asset === selectedAsset);
      } else {
        copyMatched = [...MATCHED];
      }
      if (selectedRegionCode) {
        copyUnMatched = MATCHED.filter(
          (item) => item.RegionCode === selectedRegionCode
        );
      } else {
        copyUnMatched = [...UNMATCHED];
      }

      setMatched(copyMatched.length > 0 ? [...copyMatched] : []);
      setUnMatched(copyUnMatched.length > 0 ? [...copyUnMatched] : []);
      setOrgMatched(MATCHED.length > 0 ? MATCHED : []);
      setOrgtUnMatched(UNMATCHED.length > 0 ? UNMATCHED : []);
      let data = [];
      if (currentTab === 0) {
        data = [...copyMatched];
      } else {
        data = [...copyUnMatched];
      }
      let uniqueAssets = getUniqueAssets(data);
      let uniqueRegionCode = getUniqueRegionCode(data);
      setUniqueRegionCode(uniqueRegionCode);
      setUniqueAssets(uniqueAssets);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  function getUniqueAssets(data) {
    const uniqueAssets = [...new Set(data.map((item) => item.Asset))];
    return uniqueAssets.map((asset) => ({ label: asset, value: asset }));
  }
  function getUniqueRegionCode(data) {
    const uniqueRegionCodes = [...new Set(data.map((item) => item.RegionCode))];
    return uniqueRegionCodes.map((regionCode) => ({
      label: regionCode,
      value: regionCode,
    }));
  }

  const getCategoryData = async (date) => {
    try {
      let query = "res";
      let response = await getDataFromSQLSp({
        chartSPs: true,
        spDetails: [
          {
            id: query,
            spName: "JLCHART.Usp_GetCategory1",
            parameters: { QuarterCD: date },
          },
        ],
      });
      setGetCategory(response.res);
    } catch (error) {
      // comment
    }
  };
  useEffect(() => {
    if (
      props.toolbarSelections &&
      props.toolbarSelections.filters &&
      props.toolbarSelections.filters[0]
    ) {
      setToolbarFilterValue(props.toolbarSelections.filters[0].value);
      getTableData(props.toolbarSelections.filters[0].value);
      getCategoryData(props.toolbarSelections.filters[0].value);
    }
  }, [props.toolbarSelections]);

  const handleCloseForm = (get) => {
    if (get) {
      if (
        props.toolbarSelections &&
        props.toolbarSelections.filters &&
        props.toolbarSelections.filters[0]
      ) {
        getTableData(props.toolbarSelections.filters[0].value);
        getCategoryData(props.toolbarSelections.filters[0].value);
      }
    }
    setFormVisible(false);
  };

  const handleEditRow = (index, type) => {
    const getObjectById = (categoryId, data) =>
      data.find((entry) => entry.PBI_ID === categoryId);
    if (index !== null) {
      let selectedRow;
      if (type === "matched") {
        const resultObject = getObjectById(index, matched);
        selectedRow = resultObject;
      } else {
        const resultObject = getObjectById(index, unMatched);
        selectedRow = resultObject;
      }

      setBool(true);
      setFormData({
        Code: selectedRow.Code,
        label: selectedRow.label,
        "Change Details": selectedRow["Change Details"],
        "Change Title": selectedRow["Change Title"],
        "Reason For Change": selectedRow["Reason For Change"],
        Category1: selectedRow.Category1,
        Category2: selectedRow.Category2,
        Category3: selectedRow.Category3,
        Asset: selectedRow.Asset,
        NPV: selectedRow.NPV,
        PBI_ID: selectedRow.PBI_ID,
      });
      setFormVisible(true);
    }
  };

  const handleTabChange = (event, newValue) => {
    setMatched([...orgMatched]);
    setUnMatched([...orgUnMatched]);
    setSelectedRegionCode("");
    setSelectedAsset("");
    setPage(0);
    setCurrentTab(currentTab === 0 ? 1 : 0);
    let data = newValue === 0 ? [...orgMatched] : [...orgUnMatched];
    let uniqueAssets = getUniqueAssets(data);
    let uniqueRegionCode = getUniqueRegionCode(data);
    setUniqueRegionCode(uniqueRegionCode);
    setUniqueAssets(uniqueAssets);
    setLoading(false);
    // if (newValue === 0) {
    //   if (
    //     selectedAsset &&
    //     selectedRegionCode &&
    //     uniqueAssets.some((asset) => asset.value === selectedAsset) &&
    //     uniqueRegionCode.some((region) => region.value === selectedRegionCode)
    //   ) {
    //     data = [...orgMatched];
    //     data = data.filter((item) => item.Asset === selectedAsset);
    //     data = data.filter((item) => item.RegionCode === selectedRegionCode);
    //     setMatched(data);
    //   } else {
    //     if (
    //       selectedAsset &&
    //       uniqueAssets.some((asset) => asset.value === selectedAsset)
    //     ) {
    //       data = [...orgMatched];
    //       data = data.filter((item) => item.Asset === selectedAsset);
    //       setMatched(data);
    //     } else {
    //       if (
    //         selectedRegionCode &&
    //         uniqueRegionCode.some(
    //           (region) => region.value === selectedRegionCode
    //         )
    //       ) {
    //         data = [...orgMatched];
    //         data = data.filter(
    //           (item) => item.RegionCode === selectedRegionCode
    //         );
    //         setMatched(data);
    //       } else {
    //         data = [...orgMatched];
    //         setMatched(data);
    //       }
    //     }
    //   }
    // } else {
    //   if (
    //     selectedAsset &&
    //     selectedRegionCode &&
    //     uniqueAssets.some((asset) => asset.value === selectedAsset) &&
    //     uniqueRegionCode.some((region) => region.value === selectedRegionCode)
    //   ) {
    //     data = [...orgUnMatched];
    //     data = data.filter((item) => item.Asset === selectedAsset);
    //     data = data.filter((item) => item.RegionCode === selectedRegionCode);
    //     setUnMatched(data);
    //   } else {
    //     if (
    //       selectedAsset &&
    //       uniqueAssets.some((asset) => asset.value === selectedAsset)
    //     ) {
    //       data = [...orgUnMatched];
    //       data = data.filter((item) => item.Asset === selectedAsset);
    //       setUnMatched(data);
    //     } else {
    //       if (
    //         selectedRegionCode &&
    //         uniqueRegionCode.some(
    //           (region) => region.value === selectedRegionCode
    //         )
    //       ) {
    //         data = [...orgUnMatched];
    //         data = data.filter(
    //           (item) => item.RegionCode === selectedRegionCode
    //         );
    //         setUnMatched(data);
    //       } else {
    //         data = [...orgUnMatched];
    //         setUnMatched(data);
    //       }
    //     }
    //   }
    // }
  };

  function compareValues(value1, value2) {
    if (value1 < value2) {
      return -1;
    }
    if (value1 > value2) {
      return 1;
    }
    return 0;
  }
  const getSortedRow = (id, dir, type) => {
    let data = [];
    if (type === "matched") {
      data = [...matched];
    } else {
      data = [...unMatched];
    }
    if (data.length > 0) {
      data.sort((a, b) => {
        let first = a[id];
        let second = b[id];

        // Convert to lowercase if the values are strings
        if (typeof first === "string") {
          first = first.toLowerCase();
          second = second.toLowerCase();
        }

        if (dir === "down") {
          return compareValues(second, first);
        } else {
          return compareValues(first, second);
        }
      });

      let arrowDir = dir == "down" ? "up" : "down";
      let obj = { ...Icon, [id]: arrowDir };
      setIcon(obj);
      if (type === "matched") {
        setMatched(data);
      } else {
        setUnMatched(data);
      }
    }
  };
  const DirectionArrow = (id, dir, type) => {
    return dir === "down" ? (
      <ArrowDownwardIcon
        fontSize="small"
        onClick={() => getSortedRow(id, "down", type)}
      />
    ) : (
      <ArrowUpwardIcon
        fontSize="small"
        onClick={() => getSortedRow(id, "up", type)}
      />
    );
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;

      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const renderPagingnation = () => {
    let data = [];
    if (currentTab === 0) {
      data = [...matched];
    } else {
      data = [...unMatched];
    }
    return data && data.length > 5 ? (
      <div className={classes.root}>
        <TablePagination
          variant="outlined"
          shape="rounded"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    ) : (
      ""
    );
  };
  const handleRun = async () => {
    if (!buttonLoading) {
      setButtonLoading(true);
      let val = "";
      if (
        props.toolbarSelections &&
        props.toolbarSelections.filters &&
        props.toolbarSelections.filters[0]
      ) {
        val = props.toolbarSelections.filters[0].value;
      }
      try {
        let query = "res";
        await getDataFromSQLSp({
          chartSPs: true,
          spDetails: [
            {
              id: query,
              spName: "JLChart.ExecuteRunButton",
              parameters: { QuarterDate: val },
            },
          ],
        });
        setButtonLoading(false);
        props.setToast({
          type: "success",
          severity: "success",
          message: "Done",
          status: true,
        });
        getTableData(val);
        getCategoryData(val);
      } catch {
        setButtonLoading(false);
        props.setToast({
          type: "error",
          severity: "error",
          message: "Something went wrong",
          status: true,
        });
      }
    }
  };
  const handleAssetFilter = (event) => {
    if (currentTab === 0) {
      const matchedData = filterData(
        [...orgMatched],
        event.value,
        selectedRegionCode,
        "RegionCode",
        "Asset",
        uniqueRegionCode
      );
      setMatched([...matchedData]);
      let uniqueRegionCod = getUniqueRegionCode(
        !event.value ? [...orgMatched] : [...matchedData]
      );
      setUniqueRegionCode(uniqueRegionCod);
    } else {
      const unmatchedData = filterData(
        [...orgUnMatched],
        event.value,
        selectedRegionCode,
        "RegionCode",
        "Asset",
        uniqueRegionCode
      );
      setUnMatched([...unmatchedData]);
      let uniqueRegionCod = getUniqueRegionCode(
        !event.value ? [...orgUnMatched] : [...unmatchedData]
      );
      setUniqueRegionCode(uniqueRegionCod);
    }
    setSelectedAsset(event.value);
  };

  const handleRegionCodeFilter = (event) => {
    if (currentTab === 0) {
      const matchedData = filterData(
        [...orgMatched],
        event.value,
        selectedAsset,
        "Asset",
        "RegionCode",
        uniqueAssets
      );
      setMatched([...matchedData]);
      let uniqueAsset = getUniqueAssets(
        !event.value ? [...orgMatched] : [...matchedData]
      );
      setUniqueAssets(uniqueAsset);
    } else {
      const unmatchedData = filterData(
        [...orgUnMatched],
        event.value,
        selectedAsset,
        "Asset",
        "RegionCode",
        uniqueAssets
      );
      setUnMatched([...unmatchedData]);
      let uniqueAsset = getUniqueAssets(
        !event.value ? [...orgUnMatched] : [...unmatchedData]
      );
      setUniqueAssets(uniqueAsset);
    }
    setSelectedRegionCode(event.value);
  };

  const filterData = (
    originalData,
    selectedValue,
    oppFilterValue,
    oppFilterKey,
    currentFilterKey,
    oppFilterValueArray = []
  ) => {
    let data = [...originalData];
    try {
      if (
        !oppFilterValueArray.some((region) => region.value === oppFilterValue)
      ) {
        oppFilterValue = "";
      }
      if (selectedValue) {
        data = data.filter((item) =>
          oppFilterValue
            ? item[oppFilterKey] === oppFilterValue &&
              item[currentFilterKey] === selectedValue
            : item[currentFilterKey] === selectedValue
        );
      } else if (oppFilterValue) {
        data = data.filter((item) => item[oppFilterKey] === oppFilterValue);
      }

      return data;
    } catch {
      return data;
    }
  };

  function formatValue(inputValue) {
    // Remove negative sign if present
    const positiveValue = Math.abs(inputValue);
    // Add brackets if the original value was negative
    const formattedValue = inputValue < 0 ? `(${positiveValue})` : inputValue;
    return formattedValue;
  }

  return (
    <div>
      <span
        style={{
          display: "flex",
          marginBottom: "8px",
        }}
      >
        <Typography variant="h6" style={{ marginRight: "auto" }}>
          {title}
        </Typography>
        <A_Button
          label={buttonLoading ? "RUNNING..." : "RUN"}
          style={{ width: buttonLoading ? "130px" : "80px" }}
          onClick={handleRun}
          variant="contained"
          size="medium"
          color="primary"
          endIcon={
            buttonLoading ? (
              <A_CircularIndeternment color="secondary" size={20} />
            ) : (
              <></>
            )
          }
        />
        <Tooltip title="Download Excel" aria-label="Download Excel">
          <GetAppIcon
            onClick={handleDownload}
            style={{ color: "rgb(25, 118, 210)", margin: "6px 7px 0px 14px" }}
          />
        </Tooltip>
      </span>
      <A_Divider />

      {!loading && currentTab === 0 && (
        <span
          style={{ display: "flex", justifyContent: "center", margin: "8px" }}
        >
          <A_Select
            id={"Assets"}
            style={{ marginRight: "8px" }}
            variant="standard"
            defaultValue={selectedAsset || ""}
            label={"Asset Name"}
            minWidth={150}
            native={true}
            options={uniqueAssets}
            onChange={handleAssetFilter}
          />
          <A_Select
            id={"Region"}
            variant="standard"
            defaultValue={selectedRegionCode}
            label={"Region"}
            minWidth={150}
            native={true}
            options={uniqueRegionCode || ""}
            onChange={handleRegionCodeFilter}
          />
        </span>
      )}

      {!loading && currentTab === 1 && (
        <span
          style={{ display: "flex", justifyContent: "center", margin: "8px" }}
        >
          <A_Select
            id={"Assets"}
            style={{ marginRight: "8px" }}
            variant="standard"
            defaultValue={selectedAsset || ""}
            label={"Asset Name"}
            minWidth={150}
            native={true}
            options={uniqueAssets}
            onChange={handleAssetFilter}
          />
          <A_Select
            id={"Region"}
            variant="standard"
            defaultValue={selectedRegionCode}
            label={"Region"}
            minWidth={150}
            native={true}
            options={uniqueRegionCode || ""}
            onChange={handleRegionCodeFilter}
          />
        </span>
      )}
      {loading ? (
        <>
          <Skeleton variant="text" width={80} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      ) : (
        <>
          <AppBar position="static">
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              indicatorColor="secondary"
              textColor="secondary"
              aria-label="icon label tabs example"
              className={classes.tabHeader}
            >
              <Tab label="Matched Codes" />
              <Tab label="Un-Matched Codes" />
            </Tabs>
          </AppBar>
          <TableContainer component={Paper} className={classes.container}>
            {currentTab === 0 && (
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ background: style.background }}>
                    <StyledTableCell>
                      <div className={classes.flexContainer}>
                        Asset
                        {DirectionArrow("Asset", Icon["Asset"], "matched")}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.flexContainer}>
                        Change Details
                        {DirectionArrow(
                          "Change Details",
                          Icon["Change Details"],
                          "matched"
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.flexContainer}>
                        Change Title
                        {DirectionArrow(
                          "Change Title",
                          Icon["Change Title"],
                          "matched"
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.flexContainer}>
                        Reason For Change
                        {DirectionArrow(
                          "Reason For Change",
                          Icon["Reason For Change"],
                          "matched"
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.flexContainer}>
                        Category 1
                        {DirectionArrow(
                          "Category1",
                          Icon["Category1"],
                          "matched"
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.flexContainer}>
                        Category 2
                        {DirectionArrow(
                          "Category2",
                          Icon["Category2"],
                          "matched"
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.flexContainer}>
                        Category 3
                        {DirectionArrow(
                          "Category3",
                          Icon["Category3"],
                          "matched"
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.flexContainer}>
                        {NPV_Key}
                        {DirectionArrow("NPV", Icon["NPV"], "matched")}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.flexContainer}>
                        Code {DirectionArrow("Code", Icon["Code"], "matched")}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {matched.length > 0 ? (
                    stableSort(matched, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow key={row.PBI_ID}>
                          <TableCell>{row.Asset}</TableCell>
                          <TableCell>{row["Change Details"]}</TableCell>
                          <TableCell>{row["Change Title"]}</TableCell>
                          <TableCell>{row["Reason For Change"]}</TableCell>
                          <TableCell>{row.Category1}</TableCell>
                          <TableCell>{row.Category2}</TableCell>
                          <TableCell>{row.Category3}</TableCell>
                          <TableCell>{formatValue(row.NPV)}</TableCell>
                          <TableCell>{row.Code}</TableCell>
                          <TableCell>
                            <Tooltip
                              title="Edit"
                              aria-label="Edit"
                              placement="right"
                            >
                              <EditIcon
                                key={row.PBI_ID}
                                id={row.PBI_ID}
                                onClick={() =>
                                  handleEditRow(row.PBI_ID, "matched")
                                }
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        {message.NO_DATA_AVAILABLE}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
            {currentTab === 1 && (
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ background: style.background }}>
                    <StyledTableCell>
                      <div className={classes.flexContainer}>
                        Asset
                        {DirectionArrow("Asset", Icon["Asset"], "unmatched")}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.flexContainer}>
                        Change Details
                        {DirectionArrow(
                          "Change Details",
                          Icon["Change Details"],
                          "unmatched"
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.flexContainer}>
                        Change Title
                        {DirectionArrow(
                          "Change Title",
                          Icon["Change Title"],
                          "unmatched"
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.flexContainer}>
                        Reason For Change
                        {DirectionArrow(
                          "Reason For Change",
                          Icon["Reason For Change"],
                          "unmatched"
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.flexContainer}>
                        Category 1
                        {DirectionArrow(
                          "Category 1",
                          Icon["Category 1"],
                          "unmatched"
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.flexContainer}>
                        Category 2
                        {DirectionArrow(
                          "Category 2",
                          Icon["Category 2"],
                          "unmatched"
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.flexContainer}>
                        Category 3
                        {DirectionArrow(
                          "Category 3",
                          Icon["Category 3"],
                          "unmatched"
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.flexContainer}>
                        {NPV_Key}
                        {DirectionArrow("NPV", Icon["NPV"], "unmatched")}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.flexContainer}>
                        Code {DirectionArrow("Code", Icon["Code"], "unmatched")}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unMatched.length > 0 ? (
                    stableSort(unMatched, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow key={row.PBI_ID}>
                          <TableCell>{row.Asset}</TableCell>
                          <TableCell>{row["Change Details"]}</TableCell>
                          <TableCell>{row["Change Title"]}</TableCell>
                          <TableCell>{row["Reason For Change"]}</TableCell>
                          <TableCell>{row.Category1}</TableCell>
                          <TableCell>{row.Category2}</TableCell>
                          <TableCell>{row.Category3}</TableCell>
                          <TableCell>{formatValue(row.NPV)}</TableCell>
                          <TableCell>{row.Code}</TableCell>
                          <TableCell>
                            <Tooltip
                              title="Edit"
                              aria-label="Edit"
                              placement="right"
                            >
                              <EditIcon
                                key={row.PBI_ID}
                                id={row.PBI_ID}
                                onClick={() =>
                                  handleEditRow(row.PBI_ID, "unmatched")
                                }
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        {message.NO_DATA_AVAILABLE}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
            {renderPagingnation()}
          </TableContainer>
        </>
      )}

      <A_SimpleDialog
        open={isFormVisible}
        fullScreen={false}
        height="400px"
        fullWidth={true}
        title={bool ? "Edit Category Code" : "Add item"}
        maxWidth={"sm"}
        onClose={() => handleCloseForm(false)}
        dialogContent={
          <KtCodesMainForm
            formData={formData}
            handleCloseForm={handleCloseForm}
            isEdited={bool}
            getCategory={getCategory}
            toolbarFilterValue={toolbarFilterValue}
          />
        }
      />
    </div>
  );
};
KtCodesMain.propTypes = {
  content: PropTypes.any,
  editData: PropTypes.any,
  isEditing: PropTypes.any,
  selectedTab: PropTypes.any,
  endEditing: PropTypes.any,
  setSaveData: PropTypes.any,
  toolbarSelections: PropTypes.any,
  updateSavedData: PropTypes.any,
  updateQuarterCode: PropTypes.any,
  setToast: PropTypes.any,
};
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    toolbarSelections: state.manageToolbarSelections,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, toastActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(KtCodesMain);
