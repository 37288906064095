import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import {
  getQuestionsJsonFromSurvey,
  getAllPillar,
  getSubPillar,
} from "../ESG_API";
import { organizeQuestionsFlat } from "../utils";
// import { fas } from "@fortawesome/free-solid-svg-icons";
// import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#757474",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const QuestionnaireScore = () => {
  const [questions, setQuestions] = useState([]);
  const [pillars, setPillars] = useState([]);
  const [subpillars, setSubpillars] = useState([]);
  //   const [dropValue, setDropValue] = useState(true);

  useEffect(() => {
    const getQuestionsSurveyData = async () => {
      const questionsData = await getQuestionsJsonFromSurvey(211);
      const pillarsData = await getAllPillar();
      const subPillarData = await getSubPillar();
      const flatQuestions = organizeQuestionsFlat(questionsData);
      setQuestions(flatQuestions);
      setPillars(pillarsData);
      setSubpillars(subPillarData);
    };

    getQuestionsSurveyData();
  }, []);

  console.log({ questions, pillars, subpillars });
  return (
    <TableContainer
      component={Paper}
      style={{ display: "flex", flexDirection: "column", alignItems: "end" }}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Pillar Name</StyledTableCell>
            <StyledTableCell align="center">SubPillar Name</StyledTableCell>
            <StyledTableCell align="center">Question</StyledTableCell>
            <StyledTableCell align="center">Breakdown</StyledTableCell>
            <StyledTableCell align="center">Question Score</StyledTableCell>
            <StyledTableCell align="center">Response Logic</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questions &&
            questions.map((question, idx) => {
              const pillar =
                pillars &&
                pillars.find(
                  (item) =>
                    item.questioncategoryid == question.questionCategoryId
                );
              const subpillar =
                subpillars &&
                subpillars.find(
                  (item) =>
                    item.questionsubcategoryid == question.questionSubCategoryId
                );
              return (
                <>
                  <TableRow key={idx}>
                    <TableCell
                      component="th"
                      scope="row"
                      rowSpan={
                        question.subQuestion && question.subQuestion.length > 0
                          ? question.subQuestion.length
                          : 1
                      }
                    >
                      {pillar ? pillar.name : ""}
                    </TableCell>
                    <TableCell
                      align="center"
                      rowSpan={
                        question.subQuestion && question.subQuestion.length > 0
                          ? question.subQuestion.length
                          : 1
                      }
                    >
                      {subpillar ? subpillar.name : ""}
                    </TableCell>
                    <TableCell
                      align="center"
                      rowSpan={
                        question.subQuestion && question.subQuestion.length > 0
                          ? question.subQuestion.length
                          : 1
                      }
                    >
                      {question.questionText}
                    </TableCell>
                    <TableCell align="center">
                      {question.subQuestion
                        ? question.subQuestion[0].text
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      <TextField type="number"></TextField>
                    </TableCell>
                    <TableCell
                      align="center"
                      rowSpan={
                        question.subQuestion && question.subQuestion.length > 0
                          ? question.subQuestion.length
                          : 1
                      }
                    >
                      <Select value={true}>
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                  {question.subQuestion && question.subQuestion.length > 1 ? (
                    question.subQuestion.slice(1).map((sub, idx) => (
                      <TableRow key={idx}>
                        <TableCell align="center">{sub.text}</TableCell>
                        <TableCell align="center">
                          <TextField type="number"></TextField>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
        </TableBody>
      </Table>
      <Button variant="contained" style={{ margin: "10px 10px 10px 0" }}>
        Save
      </Button>
    </TableContainer>
  );
};

export default QuestionnaireScore;
