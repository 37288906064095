import { Button, Chip, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@material-ui/core'
import React from 'react'
import A_SummerNote from '../../../../Atoms/Inputs/SummerNote/A_SummerNote';
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
    addQuestionnaire: {
      position: "fixed",
      top: "106px",
      right: "26px",
      zIndex: "2",
    },
    customWidth: {
      width: "40.5ch",
    },
    dialog: {
      width: "100%",
    },
    addForm: {
      display: "flex",
      justifyContent: "space-between",
    },
    questionText: {
      width: "350px",
      marginRight: "30px",
    },
    logic: {
      width: "150px",
    },
    type: {
      width: "496px",
    },
    addIcon: {
      marginTop: "20px",
      marginLeft: "13px",
    },
    unitOfMeasure: {
      marginLeft: "5px",
      width: "136px",
    },
    chipData: {
      marginRight: "5px",
    },
    chip: {
      marginTop: "5px",
    },
    static: {
      marginTop: "-15px",
    },
    rating: {
      marginTop: "5px",
    },
    menuitemtext: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    errorMessage: {
      background: "rgb(253, 237, 237)",
      borderRadius: "4px",
      textAlign: "center",
      color: "rgb(102, 60, 0)",
      marginBottom: "1%",
      display: "flex",
      position: "fixed",
      left: "50%",
      transform: "translate(-50%,0%)",
      zIndex: "1",
    },
  }));

function EditQuestionForm(props) {

    let questionTypes = ["Numeric","Text","Boolean"]

    const handleGuidanceChange = (e) => {
      let guidanceText = e.replace(/<[^>]*>/g, "");
    
      const entityMap = {
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&apos;': "'",
        '&nbsp;': ' ',
        '&amp;': '&',
        '&copy;': '©',
        '&reg;': '®',
        '&trade;': '™',
        '&euro;': '€'
      };
    
      for (const entity in entityMap) {
        if (guidanceText.includes(entity)) {
          guidanceText = guidanceText.replace(new RegExp(entity, 'g'), entityMap[entity]);
        }
      }
    
      props.fullFormData.guidance = guidanceText;
      props.setGuidancetext(guidanceText);
    };
const classes = useStyles();
  return (
    <>
        <DialogContent>
        <FormControl fullWidth>
          <InputLabel >
            Pillar
          </InputLabel>
          <Select
            name="pillar"
            value={props.fullFormData.pillar}
            disabled
          >
            {props.pillar &&
              props.pillar.map((item, index) => {
                return (
                  <MenuItem value={item.name} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>
            Sub-Pillar
          </InputLabel>
          <Select
            name="subPillar"
            value={props.fullFormData.subPillar}
            disabled
          >
            {props.subPillar &&
              props.subPillar.map((item, index) => {
                return (
                  <MenuItem value={item.name} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>
            Maturity Assessment
          </InputLabel>
          <Select
            name="maturityAssessment"
            value={props.fullFormData.maturityAssessment}
            disabled
          >
            {props.maturityassessment &&
              props.maturityassessment.map((item) => {
                return (
                  <MenuItem value={item.name} key={item.maturityassessmentid}>
                    {item.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
        >
          <InputLabel>Is Conditional Question ?</InputLabel>
          <Select
            name="isConditionalQuestion"
            value={props.fullFormData.isConditionalQuestion}
            disabled
          >
            {props.parentquestion && props.parentquestion.length > 0 && (
              <MenuItem value="Yes">Yes</MenuItem>
            )}
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
        {props.fullFormData.isConditionalQuestion == "Yes" && (
          <FormControl
            fullWidth
          >
            <InputLabel>Parent Question</InputLabel>
            <Select
              name="parentQuestion"
              value={props.fullFormData.parentQuestion}
              multiline
              disabled
            >
              {props.parentquestion && props.parentquestion.length > 0 ? (
                props.parentquestion.map((item) => {
                  return (
                    <MenuItem
                      value={item.questionText}
                      key={item.questionId}
                      className="menuitemtext"
                      style={{
                        width: "537px",
                        whiteSpace: "normal",
                        overflowWrap: "break-word",
                      }}
                    >
                      {item.questionText}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem value="" disabled>
                  No parent question found for pillar and subpillar
                </MenuItem>
              )}
            </Select>
          </FormControl>
        )}
        {props.fullFormData.isConditionalQuestion && (
          <TextField
            label="Question Text"
            name="question"
            value={props.fullFormData.question}
            
            multiline
            onChange={props.handleChange}
            fullWidth
            // className={
            //   props.fullFormData.isConditionalQuestion == "Yes"
            //     ? classes.questionText
            //     : ""
            // }
            
            // ={props.fullFormData.isConditionalQuestion == "No"}
          />
        )}
        {/* {props.fullFormData.isConditionalQuestion === "Yes" && (
          <FormControl
            className={classes.logic}
          >
            <InputLabel>Logic</InputLabel>
            <Select
              name="logic"
              value={props.fullFormData.logic}
            >
              {props.logicData &&
                props.logicData.map((item) => {
                  return (
                    <MenuItem value={item.name} key={item.logicId}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}
        <br /> */}

        {props.fullFormData.isConditionalQuestion && (
          <A_SummerNote
            onChange={handleGuidanceChange}
            label={"Guidance"}
            defaultValue={props.fullFormData.guidance || ""}
          ></A_SummerNote>
        )}
        {props.fullFormData.isConditionalQuestion && (
          <TextField
            label="Unit of Measure"
            name="unit"
            value={props.fullFormData.unitOfMeasure}
            onChange={props.handleChange}
            fullWidth
          />
        )}
        {props.fullFormData.isConditionalQuestion && (
          <>
            <TextField
              label="Breakdown"
              name="type"
              value={props.itemType}
              onChange={props.handleChange}
              className={classes.type}
            />
            <AddIcon
              className={classes.addIcon}
              onClick={props.handleAddType}
            />{" "}
          </>
        )}
        <br />
        <div>
          {props.listtype != [] && props.listtype.map((element) => {
            return (
              <span className={classes.chipData}>
                <Chip
                  className={classes.chip}
                  label={element.text}
                  variant="outlined"
                  onDelete={() => props.handleListDelete(element.text)}
                  style={{ maxWidth: "200px", wordWrap: "break-word" }}
                />
              </span>
            );
          })}
        </div>
        <br />
        {props.fullFormData.isConditionalQuestion && (
          <FormControl
            fullWidth
          >
            <InputLabel>
              Question Answer Type
            </InputLabel>
            <Select
              name="questionAnswerType"
              value={props.fullFormData.questionAnswerType}
              fullWidth
              disabled
            >
              {questionTypes.map((item) => {
                    return (
                      <MenuItem
                        value={item}
                        key={item}
                      >
                        {item}
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>
        )}  
        <FormControl
          fullWidth
        >
          <InputLabel>PAI Framework</InputLabel>
          <Select
            name="paiFramework"
            value={props.fullFormData.paiFramework}
            disabled
          >
            {props.paiframework &&
              props.paiframework.map((item) => {
                return (
                  <MenuItem value={item.name} key={item.paiFrameworkid}>
                    {item.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
        >
          <InputLabel>Data Convergence Framework</InputLabel>
          <Select
            name="dataConvergenceFramework"
            value={props.fullFormData.dataConvergenceFramework}
            disabled
          >
            {props.dataconvergenceframework &&
              props.dataconvergenceframework.map((item) => {
                return (
                  <MenuItem
                    key={item.dataConvergenceFrameworkid}
                    value={item.name}
                  >
                    {item.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
        >
          <InputLabel>Import Historic Data</InputLabel>
          <Select
            name="newQuestionOrImportData"
            value={props.fullFormData.newQuestionOrImportData}
            disabled
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          fullWidth
        >
          <InputLabel>
            Inherit  &#40;if answer given in a prior year, do not ask question
            again/Updated yearly&#41;
          </InputLabel>
          <Select
            name="staticOrUpdatedYearly"
            value={props.fullFormData.staticOrUpdatedYearly}
            disabled
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
        <FormControl disabled>
          <FormLabel id="demo-row-radio-buttons-group-label">Rating</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={props.fullFormData.rating}
          >
            <FormControlLabel value="No" control={<Radio />} label="No" />
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
          </RadioGroup>
        </FormControl>
        </DialogContent>
        <DialogActions>
        <Button
          onClick={props.handleSubmit}
          color="primary"
          variant="contained"
          style={{ marginTop: "-20px", marginRight: "12px" }}
        //   disabled={isButtonDisabled}
        >
           Update
        </Button>
      </DialogActions>
    </>
  )
}

EditQuestionForm.propTypes = {
  fullFormData:PropTypes,
  pillar:PropTypes,
  subPillar:PropTypes,
  maturityassessment:PropTypes,
  parentquestion:PropTypes,
  paiframework:PropTypes,
  dataconvergenceframework:PropTypes,
  listtype:PropTypes,
  handleListDelete:PropTypes,
  handleChange:PropTypes,
  setGuidancetext:PropTypes,
  itemType:PropTypes,
  handleAddType:PropTypes,
  handleSubmit:PropTypes,
}

export default EditQuestionForm