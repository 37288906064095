import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { utils, writeFile } from "xlsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, CircularProgress } from "@material-ui/core";
import { SurveyFilter, SurveyFilterYear } from "../components";
import InvestmentFilter from "../components/InvestmentFilter";
import * as esgApi from "../ESG_API/index";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#757474",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    width: 400,
    minWidth: 300,
    whiteSpace: "wrap",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    position: "relative",
  },
  button: {
    background: "#d7d7d7",
  },
  cell: {
    width: 400,
    whiteSpace: "wrap",
  },
  tableContainer: {
    height: 600,
    overflowY: "scroll",
  },
});

const ESGReport = () => {
  const classes = useStyles();
  const [surveyId, setSurveyId] = useState("");
  const [surveyYear, setSurveyYear] = useState("");
  const [previousSurveyYear, setPreviousSurveyYear] = useState("");
  const [investmentId, setInvestmentId] = useState("");
  const [pillars, setPillars] = useState([]);
  const [subPillars, setSubPillars] = useState([]);
  const [maturityAssesments, setMaturityAssesments] = useState([]);
  const [questionsAndResponses, setQuestionsAndResponses] = useState({});
  const [loading, setLoading] = useState(false);
  const [previousQuestionsAndResponses, setPreviousQuestionsAndResponses] =
    useState({});
  const [rows, setRows] = useState([]);
  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    if (
      pillars.length != 0 &&
      subPillars.length != 0 &&
      maturityAssesments.length != 0 &&
      questionsAndResponses.questions
    ) {
      let rows = [];

      questionsAndResponses.questions.forEach((question) => {
        let obj = {};
        obj.id = question.questionId;
        let pillar = pillars.find(
          (item) => item.questioncategoryid == question.questionCategoryId
        );
        obj.pillar = pillar && pillar.name;
        let subPillar = subPillars.find(
          (item) => item.questionsubcategoryid == question.questionSubCategoryId
        );
        obj.subPillar = subPillar && subPillar.name;
        let maturityAssesment = maturityAssesments.find(
          (item) => item.maturityassessmentid == question.maturityAssesmentId
        );
        obj.maturity = maturityAssesment && maturityAssesment.name;
        obj.question = question.questionText;
        let response = questionsAndResponses.responses.find(
          (item) => item.questionId == question.questionId
        );
        let prevResponse =
          previousQuestionsAndResponses.responses &&
          previousQuestionsAndResponses.responses.find(
            (item) => item.questionId == question.questionId
          );
        if (question.hasSubQuestions) {
          obj.subQuestions = [];

          question.subQuestion.forEach((element) => {
            let subQuestion = element;
            let subResponseText = "-";
            let prevSubResponseText = "-";
            let response = questionsAndResponses.responses.find(
              (item) => item.questionId === question.questionId
            );
            let prevResponse =
              previousQuestionsAndResponses.responses &&
              previousQuestionsAndResponses.responses.find(
                (item) => item.questionId === question.questionId
              );
            if (response) {
              let subResponse = response.esgSubResponseDtos.find(
                (item) => item.subQuestionId === subQuestion.subQuestionId
              );
              if (subResponse && subResponse.responseText) {
                subResponseText = subResponse.responseText;
              }
            }
            if (prevResponse) {
              let prevSubResponse = prevResponse.esgSubResponseDtos.find(
                (item) => item.subQuestionId === subQuestion.subQuestionId
              );
              if (prevSubResponse && prevSubResponse.responseText) {
                prevSubResponseText = prevSubResponse.responseText;
              }
            }
            obj.subQuestions.push({
              name: subQuestion.text,
              year1answer: subResponseText,
              year2answer: prevSubResponseText,
            });
          });
        } else {
          obj.subQuestions = null;
          if (response) {
            obj.answerText1 = response.responseText;
          } else {
            obj.answerText1 = null;
          }

          if (prevResponse) {
            obj.answerText2 = prevResponse.responseText;
          } else {
            obj.answerText2 = null;
          }
        }

        if (response && response.comment) {
          obj.comment1 = response.comment;
        } else {
          obj.comment1 = "-";
        }

        if (prevResponse && prevResponse.comment) {
          obj.comment2 = prevResponse.comment;
        } else {
          obj.comment2 = "-";
        }

        rows.push(obj);
      });

      setRows(rows);
    }
  }, [
    pillars,
    subPillars,
    maturityAssesments,
    questionsAndResponses,
    previousQuestionsAndResponses,
  ]);

  useEffect(() => {
    if (surveyId != "" && investmentId != "") {
      const getQuestionsAndResponses = async () => {
        setLoading(true);
        let response = await esgApi.getReportData(surveyId, investmentId);
        setQuestionsAndResponses(response);
        setLoading(false);
        setPreviousQuestionsAndResponses({});
        let previousYearSurvey = await esgApi.getPreviousPublishedSurvey(
          surveyId
        );
        setPreviousSurveyYear(previousYearSurvey.year);
        let previousYearQuestionAndResponses = await esgApi.getReportData(
          previousYearSurvey.surveyId,
          investmentId
        );
        setPreviousQuestionsAndResponses(previousYearQuestionAndResponses);
      };

      getQuestionsAndResponses();
    }
  }, [surveyId, investmentId]);

  useEffect(() => {
    if (rows.length != 0) {
      let data = [];

      for (let i = 0; i < rows.length; i++) {
        let item = rows[i];

        if (!item.answerText1 && item.subQuestions) {
          for (let j = 0; j < item.subQuestions.length; j++) {
            data.push({
              pillar: item.pillar,
              subPillar: item.subPillar,
              question: item.question,
              maturity: item.maturity,
              subQuestion: item.subQuestions[j].name,
              answer1: item.subQuestions[j].year1answer,
              comment1: item.comment1,
              answer2: item.subQuestions[j].year2answer,
              comment2: item.comment2,
            });
          }
        } else {
          data.push({
            pillar: item.pillar,
            subPillar: item.subPillar,
            question: item.question,
            maturity: item.maturity,
            subQuestion: "-",
            answer1: item.answerText1,
            comment1: item.comment1,
            answer2: item.answerText2,
            comment2: item.comment2,
          });
        }
      }

      setExcelData(data);
    }
  }, [rows]);

  useEffect(() => {
    const getPillarsAndSubPillars = async () => {
      setLoading(true);
      let localPillars = await esgApi.getAllPillar();
      setPillars(localPillars);
      let localSubPillars = await esgApi.getSubPillar();
      setSubPillars(localSubPillars);
      let localMaturityAssesments = await esgApi.getAllMaturityAssessment();
      setMaturityAssesments(localMaturityAssesments);
    };
    getPillarsAndSubPillars();
  }, []);

  const handleGenerateExcel = () => {
    const worksheet = createWorksheetFromData(excelData);

    // Apply center alignment to all cells
    const range = utils.decode_range(worksheet["!ref"]);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = { c: C, r: R };
        const cellRef = utils.encode_cell(cellAddress);
        if (!worksheet[cellRef]) continue;
        worksheet[cellRef].s = {
          alignment: { horizontal: "center", vertical: "center" },
        };
      }
    }

    let mergeArray = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } },
      { s: { r: 0, c: 5 }, e: { r: 0, c: 6 } },
      { s: { r: 0, c: 7 }, e: { r: 0, c: 8 } },
    ];

    let rowNum = 2;

    rows.forEach((question) => {
      if (question.subQuestions && question.subQuestions.length != 0) {
        let endingRow = rowNum + question.subQuestions.length - 1;
        mergeArray.push({ s: { r: rowNum, c: 0 }, e: { r: endingRow, c: 0 } });
        mergeArray.push({ s: { r: rowNum, c: 1 }, e: { r: endingRow, c: 1 } });
        mergeArray.push({ s: { r: rowNum, c: 2 }, e: { r: endingRow, c: 2 } });
        mergeArray.push({ s: { r: rowNum, c: 3 }, e: { r: endingRow, c: 3 } });
        mergeArray.push({ s: { r: rowNum, c: 6 }, e: { r: endingRow, c: 6 } });
        mergeArray.push({ s: { r: rowNum, c: 8 }, e: { r: endingRow, c: 8 } });
        rowNum = endingRow + 1;
      } else {
        rowNum++;
      }
    });

    worksheet["!merges"] = mergeArray;
    const workbook = { Sheets: { Sheet1: worksheet }, SheetNames: ["Sheet1"] };
    writeFile(workbook, "example.xlsx");
  };

  const createWorksheetFromData = (data) => {
    const sheetData = data.map((obj) => {
      const row = {};
      Object.keys(obj).forEach((key) => {
        row[key] = obj[key];
      });
      return row;
    });

    const headers = [
      "pillar",
      "subPillar",
      "maturity",
      "question",
      "subQuestion",
      "answer1",
      "comment1",
      "answer2",
      "comment2",
    ];

    const headersShown = [
      "Questionnare name",
      "Questionnare name",
      "Questionnare name",
      "Questionnare name",
      "Questionnare name",
      surveyYear,
      surveyYear,
      previousSurveyYear,
      previousSurveyYear,
    ];

    const subHeadersShown = [
      "pillar",
      "subPillar",
      "maturity",
      "question",
      "subQuestion",
      "answer",
      "comment",
      "answer",
      "comment",
    ];

    const wsData = [
      headersShown,
      subHeadersShown,
      ...sheetData.map((row) => headers.map((header) => row[header])),
    ];
    const ws = utils.aoa_to_sheet(wsData);
    return ws;
  };

  return (
    <div>
      <SurveyFilter
        selectedSurveyId={surveyId}
        filtersurveyyear={surveyYear}
        setSelectedSurveyId={setSurveyId}
      />
      <SurveyFilterYear
        selectedSurveyId={surveyId}
        filtersurveyyear={surveyYear}
        setSelectedSurveyId={setSurveyId}
        setSurveyFilterYear={setSurveyYear}
      />
      <InvestmentFilter
        selectedInvestmentId={investmentId}
        setSelectedInvestmentId={setInvestmentId}
        style={{
          position: "fixed",
          top: "108px",
          left: "605px",
          zIndex: "2",
          backgroundColor: "#ffff",
          width: "150px",
          height: "38px",
          borderRadius: "3px",
        }}
      />
      {!loading ? (
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead
              style={{
                zIndex: 0,
                position: "sticky",
                top: "0px",
              }}
            >
              <TableRow>
                <StyledTableCell align="center" colSpan={4}>
                  Questionnaire name
                </StyledTableCell>
                <StyledTableCell align="center" colSpan={2}>
                  {surveyYear ? surveyYear : ""}
                </StyledTableCell>
                <StyledTableCell align="center" colSpan={2}>
                  {previousSurveyYear ? previousSurveyYear : ""}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell align="center">Pillar</StyledTableCell>
                <StyledTableCell align="center">Sub-pillar</StyledTableCell>
                <StyledTableCell align="center">Question</StyledTableCell>
                <StyledTableCell align="center">Sub-question</StyledTableCell>
                <StyledTableCell align="center">Answer</StyledTableCell>
                <StyledTableCell align="center">Comment</StyledTableCell>
                <StyledTableCell align="center">Answer</StyledTableCell>
                <StyledTableCell align="center">Comment</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ overflowY: "auto", maxHheight: "350px" }}>
              {rows &&
                rows.map((row) => (
                  <>
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {row.pillar}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.subPillar}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.question}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {!row.text
                          ? !row.subQuestions
                            ? "-"
                            : row.subQuestions[0]?.name
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {!row.answerText1
                          ? !row.subQuestions
                            ? "-"
                            : row.subQuestions[0]?.year1answer
                          : row.answerText1}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div>{row.comment1}</div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {!row.answerText2
                          ? !row.subQuestions
                            ? "-"
                            : row.subQuestions[0]?.year2answer
                          : row.answerText2}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.comment2}
                      </StyledTableCell>
                    </StyledTableRow>
                    {!row.answerText1 &&
                      row.subQuestions &&
                      row.subQuestions.map((subquestion, index) => {
                        if (index == 0) {
                          return null;
                        }
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                            ></StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                            <StyledTableCell align="center">
                              {subquestion.name}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {subquestion.year1answer}
                            </StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                            <StyledTableCell align="center">
                              {subquestion.year2answer}
                            </StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <CircularProgress />
        </div>
      )}
      <div
        style={{ display: "flex", justifyContent: "end", paddingTop: "10px" }}
      >
        <Button
          className={classes.button}
          disabled={loading || !previousQuestionsAndResponses.responses}
          onClick={handleGenerateExcel}
        >
          Export excel
        </Button>
      </div>
    </div>
  );
};

export default ESGReport;
