export const ApiEndpoints = {
  GET_PILLAR: "/esg/question/questioncategory",
  GET_SUBPILLAR: "/esg/question/questionsubcategory/",
  GET_MATURITY_ASSESSMENT: "/esg/question/maturityassessment",
  GET_PAI_FRAMEWORK: "/esg/question/paiframework",
  GET_DATA_CONVERGENCE: "/esg/question/dataconvergenceframework",
  GET_QUESTION_ANS_TYPE: "/esg/question/questionanswertype",
  ADD_QUESTION: "/esg/question",
  GET_PARENT_QUESTION: "/esg/question/parent",
  UPDATE_QUESTION: "/esg/question/",
  SURVEY_QUESTION: "/esg/survey",
  ADD_SURVEY: "/esg/survey",
  GET_BOOLEANTYPE_QUESTION: "/esg/question/survey",
  GET_SURVEY: "/esg/survey/",
  POST_SURVEY_QUESTION_MAP: "/esg/survey/questions",
  PUT_SURVEY_QUESTION_MAP: "/esg/survey",
  DELETE_QUESTION: "/esg/question/",
  PUBLISH_SURVEY: "/esg/survey",
  GET_INVESTMENT: "/esg/investment",
  GET_INVESTMENT_CRMID: "/esg/investment/crm/",
  GET_RESPONSE_APPROVAL_ASSETID: "/esg/responseapproval/investment/",
  RESPONSE_APPROVAL_SURVEYID: "/esg/responseapproval/survey/",
  RESPONSE_APPROVAL: "/esg/responseapproval",
  RESPONSE_QUESTION: "/esg/response",
  GET_RESPONSE_QUESTION: "/esg/response/survey",
  GET_REPORT_DATA: "/esg/response/report",
  GET_OTHER_INVESTMENT: "/esg/survey/investment",
};
