/* eslint-disable */
import React from "react";
import "./layout.scss";
import _ from "lodash";
import throttle from "lodash/throttle";
import A_Card from "../../../Atoms/Surfaces/Cards/A_Card.jsx";
import InlineRecords from "../../../../../Assets/Images/Defaults/inline-records.JPG";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog.jsx";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button.jsx";
import M_NewsCard from "../../../Molecules/Common/Renderers/Views/NewsCards/M_NewsCard";
import M_ChartCard from "../../../Molecules/Common/Renderers/Views/Charts/M_ChartCard";
import M_MapCard from "../../../Molecules/Common/Renderers/Views/MapCards/M_MapCard";
import ViewFields from "../Fields/ViewFields";
import DisplaySections from "../DisplaySection/DisplaySection";
import DisplayTabs from "../DisplayTabs/DisplayTabs";
import M_ResultCard from "../../../Molecules/Common/Renderers/Views/ResultCards/M_ResultCard";
import ExcelTemplate from "../ExcelTemplate/ExcelTemplate";
import ReportEditor from "../ReportEditor/MainPage/ReportEditor";
import M_ManageEntityForm from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm";
import M_DropableFileUpload from "../../../Molecules/Common/File Upload/M_DropableFileUpload";
import PreviousTemplate from "../../../Molecules/Common/Renderers/Views/PreviousTemplate/PreviousTemplate";
import PreviousUploads from "../../../Molecules/Common/Renderers/Views/PreviousTemplate/PreviousUploads";
import ErrorBoundary from "../../../Molecules/Common/error/ErrorBoundary";
import O_ChartDataEntry from "../../../Organisms/Common/ChartData/O_ChartDataEntry";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import O_DealTimeline from "../../../Organisms/Common/DealTimeline/O_DealTimeline";
import O_Pipeline from "../../../Organisms/Common/Pipeline/O_Pipeline";
import PipeLineFilter from "../../../Organisms/Common/Pipeline/PipeLineFilter";
import KpiCallBox from "../../../Organisms/Common/KpiCallBox/KpiCallBox";
import M_DealStatusBar from "../../../Molecules/Common/StatusBar/M_StatusBar";
import O_Historychart from "../../..//Organisms/Common/Historychart/O_Historychart";
import O_IRCDetails from "../../../Organisms/Common/IRCDetails/O_IRCDetails";
import O_MeetingSlotCard from "../../../Organisms/Common/MeetingSlotCard/O_MeetingSlotCard";
import Guidelines from "../../../Organisms/Common/Guidelines/Guidelines";
import ChecklistMaster from "../Checklist/ChecklistMaster";
import DealTeam from "../../../Pages/Common/DealTeam/DealTeam";
import O_CheckList from "../../../Organisms/Common/Checklist/O_Checklist";
import MapGoogle from "../../../Atoms/Map/A_GoogleMap";
import O_activeFacility from "../../../Organisms/Common/ActiveFacility/O_activeFacility";
import O_LendersApproached from "../../../Organisms/Common/LendersApproached/O_lendersApproached";
import M_MeetingPreference from "../MeetingPreferences/M_MeetingPreference";
import Image from "../../../Atoms/Image/A_Image";
import ManageInvoices from "../../../Molecules/Common/PDFReader/ManageInvoices";
import M_ListDetails from "../../../Molecules/Common/DealAdministartion/M_ListDetails";
import SubmissionApproval from "../MonthlySubmission/submissionApproval";
import SubmissionReview from "../MonthlySubmission/submissionReview";
import PortfolioApproval from "../PortfolioManagement/PortfolioApproval";
import BudgetApproval from "../Budget&Approval/budgetApproval";
import ApproveAndReject from "../Budget&Approval/approveAndReject";
import M_PortfolioFileUpload from "../../../Molecules/Common/File Upload/M_PortfolioFileUpload";
import { addDynamicValueforHeader } from "../../../../../Utils/Helpers/generciParamHelper";
// import ExternalCommittee from "../../ExternalCommittee/externalCommittee"; Will used this later
import ImageGallery from "../../../Organisms/Common/ImageGallery/ImageGallery";
import Annoucment from "../../../Molecules/Announcment/Annoucement";
import PropTypes from "prop-types";
import GenericList from "../../../Organisms/Common/GenericList/GenericList";
import EsgHeader from "../../../Pages/Common/Esg/EsgHeader";
import EsgResponsePage from "../../../Pages/Common/Esg/EsgResponse/EsgResponsePage";
import { clearChartKeys } from "../PageLayout/P_PageLayout.Helper";
import QuickLinks from "../../../Molecules/QuickLink/QuickLinks";
import ImageCard from "../../../Molecules/ImageCard/ImageCard.jsx";
import O_NewsPortal from "../../../Organisms/Common/NewsPortal/O_NewsPortal";
import M_PDFReader from "../../../Molecules/Common/PDFReader/M_PDFReader";
import O_InvestmentCase from "../../../Organisms/Common/InvestmentCase/O_InvestmentCase";
import {
  articleTypes,
  contentTypes,
  deviceTypes,
  externalTypes,
} from "./layout.Helper";
import ManualUpdate from "../../../Molecules/Common/Renderers/Views/ManualUpdate/ManualUpdate";
import O_ConsentRequest from "../../../Organisms/Common/ConsentRequest/O_ConsentRequest";
import IRReport from "../../../Organisms/Common/IRReport/IRReport";
import KpiSqlBox from "../../../Organisms/Common/KpiCallBox/KpiSqlBox";
import QuarterlyCodeMapping from "../../../Organisms/Common/QuarterlyCodeMapping/QuarterlyCodeMapping";
import QuarterlyKtTable from "../../../Organisms/Common/QuarterlyCodeMapping/QuartelyKtTable";
import O_GSTTabs from "../../../Organisms/Common/GST/O_GSTTabs";
import { resetManagePage } from "../../../../../store/actions/managePageActions";
import A_Tearsheet from "../../../Atoms/DataDisplay/Tearsheet/A_Tearsheet";
import {
  ESGView,
  ESGSubmissionSummary,
  ESG_Report,
  ESG_Submission,
  ESG_Approval,
  ESG_ExistingQuestions,
  ESG_adminLayout,
  QuestionnaireScore,
} from "../../../Organisms/Common/ESG";
import { portfolioapproval } from "../../../Molecules/Common/PageHeaders/Toolbar/M_ToolbarFilterhelper.js";
import { resetFiltersValue } from "../../../../../store/actions/manageFilterValueAction.js";
import M_CustomCards from "./CustomCards/M_CustomCards.jsx";
import MasterMappingData from "../../../Organisms/Common/QuarterlyCodeMapping/MasterMappingData/MasterMappingData.jsx";
import KtCodesMain from "../../../Organisms/Common/QuarterlyCodeMapping/KtCodesMain/KtCodesMain.jsx";
import M_CustomView from "./CustomCards/M_CustomView.jsx";
import ImpactData from "../ImpactData.jsx";
import ProposalTracker from "../../../Organisms/Common/ProposalTracker/ProposalTracker.jsx";
class Layout extends React.Component {
  constructor(props) {
    super(props);
    let device = "";
    if (window.innerWidth < 600) {
      device = "xs";
    } else if (window.innerWidth >= 600) {
      device = "md";
    }
    this.state = {
      device: device,
      openCardZoom: false,
      openEditForm: false,
      extraEditParams: "",
      extraAddParams: "",
      currentEditParams: "",
      openAddForm: false,
      openExport: false,
      formEntity: {},
      paramEntity: {},
      templateId: {},
      recordId: null,
      isIRCEdit: false,
      optionOnAdd: false,
      deleteOnAdd: false,
      searchPipeLine: null,
      pipelineData: null,
      pipelineImages: null,
      shouldShowApproveRejectWindow: false,
      shouldShowApprovePortfolio: true,
      shouldShowsubmissionWindow: false,
      approverstage: null,
      approversubmission: null,
      fundaccountantVal: true,
      submissionVal: true,
      addEdge: null,
      editData: {},
      selectedTab: "",
      newSavedData: [],
      isEditing: false,
      QuarterCode: "",
      currentPathName: localStorage.getItem(portfolioapproval.CURRENTPATHNAME)
        ? localStorage.getItem(portfolioapproval.CURRENTPATHNAME)
        : "",
    };
    this.pipeLineDealsControll = this.pipeLineDealsControll.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleOptionOnAdd = this.handleOptionOnAdd.bind(this);
    this.handleDeleteOnAdd = this.handleDeleteOnAdd.bind(this);
    this.handleCloseForm = this.handleCloseForm.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.handleRecordID = this.handleRecordID.bind(this);
    this.handleClickOptionAdd = this.handleClickOptionAdd.bind(this);
    this.onChangeNextApprover = this.onChangeNextApprover.bind(this);
    this.onChangeSubmission = this.onChangeSubmission.bind(this);
  }

  pipeLineDealsControll(obj) {
    if (obj.type == externalTypes.PIPELINE_DATA) {
      this.setState({ pipelineData: obj.data });
    } else if (obj.type == externalTypes.IMAGE_DATA) {
      this.setState({ pipelineImages: obj.data });
    } else {
      this.setState({ searchPipeLine: obj.data });
    }
  }

  handleZoom(card, item) {
    this.setState((prevState) => {
      return {
        openCardZoom: !prevState.openCardZoom,
        cardProps: prevState.openCardZoom === true ? null : item,
      };
    });
  }
  updateEditData = (newEditData) => {
    this.setState({ editData: newEditData });
  };
  updateSelectedTab = (newTab) => {
    this.setState({ selectedTab: newTab });
  };
  updateQuarterCode = (newQuarter) => {
    this.setState({ QuarterCode: newQuarter });
  };
  updateSavedData = (newData) => {
    this.setState({ newSavedData: newData });
  };
  startEditing = () => {
    this.setState({ isEditing: true });
  };
  endEditing = () => {
    this.setState({ isEditing: false });
  };
  handleEdit(card, item) {
    const templateID = card.edit.parameters.templateId;
    let type = card.edit && card.edit.type ? card.edit.type : "";
    const { toolbarSelections } = this.props;
    let FilterParams = {};
    if (
      type &&
      type.toLowerCase() == "sql" &&
      toolbarSelections &&
      toolbarSelections.filters &&
      Array.isArray(toolbarSelections.filters) &&
      toolbarSelections.filterLength > 0 &&
      toolbarSelections.filterLength === toolbarSelections.filters.length &&
      toolbarSelections.filters.length > 0
    ) {
      toolbarSelections.filters.forEach((item) => {
        FilterParams[item.filterName] = item.value;
      });
    }
    FilterParams["id"] = this.props.params ? this.props.params.crmId : "";
    let recId = this.state.isIRCEdit ? this.state.recordId : null;
    const NewData = {
      templateId: templateID,
      recordId: recId,
      type: type.toLowerCase() == "sql" ? "sql" : "",
      FilterParams: FilterParams,
    };
    let addEdgeRequired =
      card && card.edit && card.edit.parameters && card.edit.parameters.addEdge
        ? card.edit.parameters.addEdge
        : null;

    if (
      this.props.prevEntity &&
      this.props.prevEntity.templateId !== templateID
    ) {
      this.props.resetManagePage();
    }

    if (type.toLowerCase() == "sql") {
      if (
        this.props.prevEntity &&
        this.props.prevEntity.recordId &&
        this.props.prevEntity.recordId !== this.props.params.crmId
      ) {
        this.props.resetManagePage();
      }
    }
    this.setState((prevState) => {
      return {
        openEditForm: !prevState.openEditForm,
        cardProps: prevState.openEditForm === true ? null : item,
        paramEntity: NewData,
        addEdge: addEdgeRequired,
        extraEditParams: card && card.edit && card.edit.parameters,
      };
    });
  }
  handleAdd(card, item) {
    let type = card.add && card.add.type ? card.add.type : "";
    const { toolbarSelections } = this.props;
    let FilterParams = {};
    if (
      type &&
      type.toLowerCase() == "sql" &&
      toolbarSelections &&
      toolbarSelections.filters &&
      Array.isArray(toolbarSelections.filters) &&
      toolbarSelections.filterLength > 0 &&
      toolbarSelections.filterLength === toolbarSelections.filters.length &&
      toolbarSelections.filters.length > 0
    ) {
      toolbarSelections.filters.forEach((item) => {
        FilterParams[item.filterName] = item.value;
      });
    }
    const data = this.props.currentPage.pageConfig;
    const templateId = card.add.parameters.templateId;
    const parameters = addDynamicValueforHeader(
      card.add.parameters,
      this.props.params
    );
    if (
      this.props.prevEntity &&
      this.props.prevEntity.templateId !== templateId
    ) {
      this.props.resetManagePage();
    }

    let recordId = "";
    FilterParams["id"] = this.props.params ? this.props.params.crmId : "";
    const NewData = {
      templateId,
      data,
      parameters,
      recordId,
      type: type.toLowerCase() == "sql" ? "sql" : "",
      FilterParams: FilterParams,
    };

    this.setState((prevState) => {
      return {
        openAddForm: !prevState.openAddForm,
        cardProps: prevState.openAddForm === true ? null : item,
        templateId: templateId,
        paramEntity: NewData,
        extraAddParams: card && card.edit && card.edit.parameters,
      };
    });
  }
  handleOptionOnAdd() {
    this.setState(() => {
      return {
        optionOnAdd: true,
      };
    });
  }
  handleDeleteOnAdd() {
    this.setState(() => {
      return {
        optionOnAdd: false,
      };
    });
  }

  handleCloseForm() {
    this.setState(() => {
      return {
        openEditForm: false,
        openAddForm: false,
        // cardProps: null,
        // paramEntity: null,
      };
    });
  }
  handleExport(card, item) {
    this.setState((prevState) => {
      return {
        openExport: !prevState.openExport,
        cardProps: prevState.openExport === true ? null : item,
      };
    });
  }
  handleRecordID(recId) {
    this.setState(() => {
      return {
        recordId: recId,
        isIRCEdit: true,
      };
    });
  }

  handleClickOptionAdd(item) {
    let type = item && item.type ? item.type : "";
    const { toolbarSelections } = this.props;

    let FilterParams = {};

    if (
      type &&
      type.toLowerCase() == "sql" &&
      toolbarSelections &&
      toolbarSelections.filters &&
      Array.isArray(toolbarSelections.filters) &&
      toolbarSelections.filterLength > 0 &&
      toolbarSelections.filterLength === toolbarSelections.filters.length &&
      toolbarSelections.filters.length > 0
    ) {
      toolbarSelections.filters.forEach((item) => {
        FilterParams[item.filterName] = item.value;
      });
    }

    const data =
      this.props.currentPage && this.props.currentPage.pageConfig
        ? this.props.currentPage.pageConfig
        : {};
    const templateId = item.templateId;
    const parameters = addDynamicValueforHeader(item, this.props.params);
    let recordId = "";
    const NewData = {
      templateId,
      data,
      parameters,
      recordId,
    };
    if (type.toLowerCase() == "sql") {
      NewData["id"] = this.props.params ? this.props.params.crmId : "";
      NewData["type"] = type;
      NewData["FilterParams"] = FilterParams;
    }
    if (
      this.props.prevEntity &&
      this.props.prevEntity.templateId !== templateId
    ) {
      this.props.resetManagePage();
    }

    this.setState((prevState) => {
      return {
        openAddForm: !prevState.openAddForm,
        cardProps: prevState.openAddForm === true ? null : this.props.cards[0],
        templateId: templateId,
        paramEntity: NewData,
      };
    });
  }

  onChangeNextApprover(
    nextApprover,
    indexval,
    fundaccountant,
    rejectCase,
    Invoicereadystatus
  ) {
    const { currentUser: { given_name = "", family_name = "" } = {} } =
      this.props;
    const currentUserName = `${given_name} ${family_name}`;
    this.setState({
      // shouldShowApproveRejectWindow: currentUserName === nextApprover, validates next approver based on family name for ex - Laksh Mishra
      shouldShowApproveRejectWindow:
        this.props.currentUser.email === nextApprover &&
        rejectCase != false &&
        Invoicereadystatus != false,
      approverstage: indexval,
      fundaccountantVal: fundaccountant,
    });
  }

  onChangeSubmission(
    nextApprover,
    indexval,
    fundaccountant,
    rejectCase,
    Invoicereadystatus
  ) {
    const { currentUser: { given_name = "", family_name = "" } = {} } =
      this.props;
    const currentUserName = `${given_name} ${family_name}`;
    this.setState({
      // shouldShowApproveRejectWindow: currentUserName === nextApprover, validates next approver based on family name for ex - Laksh Mishra
      shouldShowsubmissionWindow:
        this.props.currentUser.email === nextApprover &&
        rejectCase != false &&
        Invoicereadystatus != false,
      approversubmission: indexval,
      submissionVal: fundaccountant,
    });
  }

  componentDidMount() {
    clearChartKeys();
  }
  componentDidMount() {
    this.checkPathName();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPathName !== this.state.currentPathName) {
      this.checkPathName();
    }
  }
  checkPathName() {
    var pathname = window.location.pathname;
    var parts = pathname.split("/");
    if (
      this.state.currentPathName !== parts[1] &&
      this.state.currentPathName !== ""
    ) {
      this.props.resetFiltersValue();
    } else localStorage.removeItem("chartStorageKeys");
  }
  render() {
    var doit;
    const getDeviceConfig = (width) => {
      if (width < 600) {
        return deviceTypes.XS;
      } else if (width >= 600) {
        return deviceTypes.MD;
      }
    };
    const calcInnerWidth = throttle(() => {
      let deviceType = getDeviceConfig(window.innerWidth);

      if (deviceType == deviceTypes.MD || deviceType == deviceTypes.XS) {
        this.setState({ device: deviceType });
      }
    }, 500);

    window.onresize = function () {
      clearTimeout(doit);
      doit = setTimeout(calcInnerWidth, 100);
    };

    const renderCardStyle = (item, device) => {
      const resultObject = item.position[device];
      if (
        device &&
        (device === "sm" || device === "xs") &&
        resultObject &&
        resultObject["grid-column"]
      ) {
        let mobileConfig = { "grid-column": "span 12" };
        return mobileConfig;
      }
      return resultObject;
    };
    //TODO - Move showContentConditonal to separate component
    const showContentConditonal = (contentType, content, item, chartFilter) => {
      const { syncEdgeLabel } = item.header;
      const { toolbarSelections } = this.props;
      switch (contentType) {
        case contentTypes.CRMDATA:
          if (content && content.tabs) {
            return (
              <ErrorBoundary>
                <DisplayTabs
                  tabs={content.tabs}
                  chartFilter={chartFilter}
                  data={this.props.data}
                  CurrentCard={item}
                />
              </ErrorBoundary>
            );
          } else if (content && content.sections) {
            return (
              <ErrorBoundary>
                <DisplaySections sections={content.sections} />
              </ErrorBoundary>
            );
          } else if (content && content.fields) {
            return (
              <ErrorBoundary>
                <ViewFields
                  fields={content.fields}
                  syncEdgeLabel={syncEdgeLabel}
                  content={content}
                  CurrentCard={item}
                  {...this.props}
                />
              </ErrorBoundary>
            );
          }
          break;
        case contentTypes.SQLVIEW:
          if (toolbarSelections && toolbarSelections.filterLength > 0) {
            return (
              toolbarSelections.filters &&
              Array.isArray(toolbarSelections.filters) &&
              toolbarSelections.filterLength ===
                toolbarSelections.filters.length &&
              toolbarSelections.filters.length > 0 && (
                <ErrorBoundary>
                  <M_CustomView
                    tabs={content.tabs}
                    chartFilter={chartFilter}
                    data={this.props.data}
                    CurrentCard={item}
                    sections={content.sections}
                    fields={content.fields}
                    syncEdgeLabel={syncEdgeLabel}
                    content={content}
                    toolbarSelectionsFilter={{ ...toolbarSelections }}
                    spName={
                      item && item.content && item.content.spName
                        ? item.content.spName
                        : ""
                    }
                    {...this.props}
                  />
                </ErrorBoundary>
              )
            );
          } else {
            return (
              <ErrorBoundary>
                <M_CustomView
                  tabs={content.tabs}
                  chartFilter={chartFilter}
                  data={this.props.data}
                  CurrentCard={item}
                  sections={content.sections}
                  fields={content.fields}
                  syncEdgeLabel={syncEdgeLabel}
                  content={content}
                  toolbarSelectionsFilter={""}
                  spName={
                    item && item.content && item.content.spName
                      ? item.content.spName
                      : ""
                  }
                  {...this.props}
                />
              </ErrorBoundary>
            );
          }
          break;
        case contentTypes.CHART:
          return (
            <ErrorBoundary>
              <M_ChartCard
                Height={!item.minHeight ? "300px" : `${item.minHeight}px`}
                {...this.props}
                chartFilter={chartFilter}
                content={
                  content && content.chart
                    ? {
                        ...content.chart,
                        chartId: content.chart.id,
                        id: undefined,
                      }
                    : {}
                }
              />
            </ErrorBoundary>
          );
        case contentTypes.MAPS:
          return (
            <ErrorBoundary>
              <M_MapCard content={content} />
            </ErrorBoundary>
          );
        case contentTypes.MANUAL_UPDATE:
          return (
            <ErrorBoundary>
              <ManualUpdate content={content} />
            </ErrorBoundary>
          );
        case contentTypes.IR_REPORT:
          return (
            <ErrorBoundary>
              <IRReport content={content} />
            </ErrorBoundary>
          );
        case contentTypes.NEWS:
          return (
            <ErrorBoundary>
              <M_NewsCard content={content} />
            </ErrorBoundary>
          );
        case contentTypes.RESULT_TEMPLATE:
          return (
            <ErrorBoundary>
              <M_ResultCard
                content={content}
                stateObj={this.state}
                item={item}
              />
            </ErrorBoundary>
          );
        case contentTypes.DOCUMENTS:
          return (
            <ErrorBoundary>
              <M_DropableFileUpload
                content={content}
                currentPage={this.props.currentPage}
              />
            </ErrorBoundary>
          );
        case contentTypes.PORTFOLIO_FILE_UPLOAD:
          return (
            <ErrorBoundary>
              <M_PortfolioFileUpload
                content={content}
                currentPage={this.props.currentPage}
              />
            </ErrorBoundary>
          );
        case contentTypes.PREVIOUS_TEMPLATE:
          return (
            <ErrorBoundary>
              <PreviousTemplate content={content} />
            </ErrorBoundary>
          );
        case contentTypes.PREVIOUS_UPLOADS:
          return (
            <ErrorBoundary>
              <PreviousUploads content={content} />
            </ErrorBoundary>
          );
        case contentTypes.CHART_ENTRY:
          return (
            <ErrorBoundary>
              <O_ChartDataEntry chart={content.chart} />
            </ErrorBoundary>
          );
        case contentTypes.TIMELINE:
          return (
            <ErrorBoundary>
              <O_DealTimeline content={content} />
            </ErrorBoundary>
          );
        case contentTypes.GUIDELINES:
          return (
            <ErrorBoundary>
              <Guidelines content={content} />
            </ErrorBoundary>
          );
        case contentTypes.MAPGOOGLE:
          return (
            <ErrorBoundary>
              <MapGoogle content={content} data={this.props.data} />
            </ErrorBoundary>
          );
        case contentTypes.IRC_DETAIL:
          return (
            <ErrorBoundary>
              <O_Historychart
                maxHeight={content.maxHeight}
                content={content}
                callBack={this.handleRecordID}
              />
            </ErrorBoundary>
          );
        case contentTypes.DEAL_ADMINISTRATION:
          return (
            <ErrorBoundary>
              <O_IRCDetails content={content} callBack={this.handleRecordID} />
            </ErrorBoundary>
          );
        case contentTypes.BOOKING_SLOT:
          return (
            <ErrorBoundary>
              <O_MeetingSlotCard
                maxHeight={content.maxHeight}
                content={content}
              />
            </ErrorBoundary>
          );
        case contentTypes.IC_VIEW:
          return (
            <M_ListDetails
              type={externalTypes.IRC}
              id={this.state.recordId}
              resPayload={
                content && content.resPayload ? content.resPayload : ""
              }
              config={content && content.ircTabs ? content.ircTabs : []}
              content={content}
            />
          );
        case contentTypes.GENERIC_RESULT_TEMPLATE:
          return <GenericList content={content}></GenericList>;
        case contentTypes.CHECKLIST_MASTER:
          return (
            <ErrorBoundary>
              <ChecklistMaster
                content={content}
                data={
                  this.props.data && this.props.data.checklistResponse
                    ? this.props.data.checklistResponse
                    : null
                }
                percentComplete={
                  this.props.data && this.props.data.checklistStatistics
                    ? this.props.data.checklistStatistics
                    : null
                }
                dealData={this.props.data ? this.props.data : null}
              />
            </ErrorBoundary>
          );
        case contentTypes.ACTIVE_FACILITY:
          return (
            <ErrorBoundary>
              <O_activeFacility />
            </ErrorBoundary>
          );
        case contentTypes.IMPACT_DATA:
          return (
            <ErrorBoundary>
              <ImpactData content={content} />
            </ErrorBoundary>
          );
        case contentTypes.LENDERS_APPROACHED:
          return (
            <ErrorBoundary>
              <O_LendersApproached
                {...this.props}
                label={
                  content && content.content && content.content.label
                    ? content.content.label
                    : null
                }
              />
            </ErrorBoundary>
          );
        case contentTypes.MANAGED_BY:
          return (
            <ErrorBoundary>
              <DealTeam
                content={content}
                currentPage={this.props.currentPage}
              />
            </ErrorBoundary>
          );
        case contentTypes.CHECKLIST:
          return (
            <ErrorBoundary>
              <O_CheckList ircID={this.state.recordId} config={content} />
            </ErrorBoundary>
          );
        case contentTypes.MANAGE_INVOICES:
          return (
            <ErrorBoundary>
              <ManageInvoices content={content} data={this.props.data} />
            </ErrorBoundary>
          );
        case contentTypes.MEETING___PREFERENCE:
          return (
            <ErrorBoundary>
              <M_MeetingPreference content={content} />
            </ErrorBoundary>
          );
        case contentTypes.IMAGE:
          return (
            <ErrorBoundary>
              <Image content={content} {...this.props}></Image>
            </ErrorBoundary>
          );
        case contentTypes.TEARSHEET:
          return (
            <ErrorBoundary>
              <A_Tearsheet></A_Tearsheet>
            </ErrorBoundary>
          );
          T;
        // case contentTypes.DEALSEARCHBAR:
        //   return <SearchBar  />
        case contentTypes.BUDGET_APPROVAL:
          return (
            <ErrorBoundary>
              <BudgetApproval
                content={content}
                getCurrentPage={this.props.getCurrentPage}
                data={this.props}
                onChangeNextApprover={this.onChangeNextApprover}
              />
            </ErrorBoundary>
          );
        case contentTypes.SUBMISSION_REVIEW:
          return (
            <ErrorBoundary>
              <SubmissionReview
                content={content}
                getCurrentPage={this.props.getCurrentPage}
                approvalData={this.state.approversubmission}
                fundaccountantstatus={this.state.submissionVal}
              />
            </ErrorBoundary>
          );
        case contentTypes.SUBMISSION_APPROVAL:
          return (
            <ErrorBoundary>
              <SubmissionApproval
                content={content}
                getCurrentPage={this.props.getCurrentPage}
                data={this.props}
                onChangeSubmission={this.onChangeSubmission}
              />
            </ErrorBoundary>
          );
        case contentTypes.PORTFOLIOAPPROVAL:
          return (
            <ErrorBoundary>
              <PortfolioApproval
                content={content}
                getCurrentPage={this.props.getCurrentPage}
                approvalData={this.state.approversubmission}
              />
            </ErrorBoundary>
          );
        case contentTypes.APPROVE_AND_REJECT:
          return (
            <ErrorBoundary>
              <ApproveAndReject
                content={content}
                getCurrentPage={this.props.getCurrentPage}
                approvalData={this.state.approverstage}
                fundaccountantstatus={this.state.fundaccountantVal}
              />
            </ErrorBoundary>
          );
        // Will used later As per discussion
        // case contentTypes.EXTERNAL_COMMITTEE:
        //   return (
        //     <ErrorBoundary>
        //       <ExternalCommittee content={content}/>
        //     </ErrorBoundary>
        //   );
        case contentTypes.IMAGE_GALLERY:
          return (
            <ErrorBoundary>
              <ImageGallery content={content} {...this.props} />
            </ErrorBoundary>
          );
        case contentTypes.ANNOUCMENT:
          return (
            <ErrorBoundary>
              <Annoucment content={content} />
            </ErrorBoundary>
          );
        case contentTypes.QUICK_LINKS:
          return (
            <ErrorBoundary>
              <QuickLinks content={content} {...this.props} />
            </ErrorBoundary>
          );
        case contentTypes.IMAGE_CARD:
          return (
            <ErrorBoundary>
              <ImageCard content={content} {...this.props} />
            </ErrorBoundary>
          );
        case contentTypes.NEWS_PORTAL:
          return (
            <ErrorBoundary>
              <O_NewsPortal content={content} {...this.props} />
            </ErrorBoundary>
          );
        case contentTypes.STATIC_PDF:
          return (
            <ErrorBoundary>
              <M_PDFReader
                pdf={content.filename ? content.filename : ""}
                showDefaultMessage={true}
                container={content.type ? content.type : externalTypes.GENERIC}
              />
            </ErrorBoundary>
          );
        case contentTypes.INVESTMENT_CASE:
          return (
            <ErrorBoundary>
              <O_InvestmentCase content={content} />
            </ErrorBoundary>
          );
        case contentTypes.QUARTERLY_KT_CODE_MAPPING:
          return (
            <ErrorBoundary>
              <QuarterlyKtTable
                updateEditData={this.updateEditData}
                startEditing={this.startEditing}
                setSelectedTab={this.updateSelectedTab}
                newSavedData={this.state.newSavedData}
                updateSavedData={this.updateSavedData}
                QuarterCode={this.state.QuarterCode}
                isEditing={this.state.isEditing}
                chartFilter={chartFilter}
                content={content}
                {...this.props}
              />
              <QuarterlyCodeMapping
                editData={this.state.editData}
                isEditing={this.state.isEditing}
                selectedTab={this.state.selectedTab}
                newSavedData={this.state.newSavedData}
                updateSavedData={this.updateSavedData}
                updateQuarterCode={this.updateQuarterCode}
                endEditing={this.endEditing}
                chartFilter={chartFilter}
                content={content}
              />
            </ErrorBoundary>
          );
        case contentTypes.GST:
          return (
            <ErrorBoundary>
              <O_GSTTabs content={content} />
            </ErrorBoundary>
          );
        case contentTypes.ESG:
          return (
            <ErrorBoundary>
              <ESG_adminLayout content={content} />
            </ErrorBoundary>
          );
        case contentTypes.ESGUserView:
          return (
            <ErrorBoundary>
              <ESGView content={content} />
            </ErrorBoundary>
          );
        case contentTypes.ESGExistingQuestion:
          return (
            <ErrorBoundary>
              <ESG_ExistingQuestions />
            </ErrorBoundary>
          );
        case contentTypes.ESGSubmissionSummary:
          return (
            <ErrorBoundary>
              <ESGSubmissionSummary />
            </ErrorBoundary>
          );
        case contentTypes.ESGReport:
          return (
            <ErrorBoundary>
              <ESG_Report />
            </ErrorBoundary>
          );
        case contentTypes.ESGSubmission:
          return (
            <ErrorBoundary>
              <ESG_Submission />
            </ErrorBoundary>
          );
        case contentTypes.ESGApproval:
          return (
            <ErrorBoundary>
              <ESG_Approval />
            </ErrorBoundary>
          );
        case contentTypes.QuestionnaireScore:
          return (
            <ErrorBoundary>
              <QuestionnaireScore />
            </ErrorBoundary>
          );
        case contentTypes.MASTERMAPPINGDATA:
          return (
            <ErrorBoundary>
              <MasterMappingData content={content} />
            </ErrorBoundary>
          );
        case contentTypes.QUARTERLY_KT_CODE:
          return (
            <ErrorBoundary>
              <KtCodesMain content={content} />
            </ErrorBoundary>
          );
        case contentTypes.PROPOSAL_TRACKER:
          return (
            <ErrorBoundary>
              <ProposalTracker content={content} />
            </ErrorBoundary>
          );
      }
    };
    const getArticle = (item, device, index, isReadOnly = false) => {
      const {
        title,
        toolbar,
        showPic,
        chartFilter,
        addFiles,
        boldTitle,
        show = true,
      } = item.header;
      const { type, content } = item.content;
      if (
        item.content &&
        item.content.customCardName &&
        item.content.customCardName == "meetingSync"
      ) {
        if (
          this.props.userid !== this.props.params.crmId ||
          !this.props.isMinervaUser
        ) {
          return "";
        }
      }
      if (
        item.content &&
        item.content.customCardName &&
        item.content.customCardName == "meetingSyncEA"
      ) {
        if (this.props.userid !== this.props.params.crmId || !this.props.isEA) {
          return "";
        }
      }
      if (
        type === contentTypes.APPROVE_AND_REJECT &&
        !this.state.shouldShowApproveRejectWindow
      ) {
        return "";
      }
      if (
        type === contentTypes.PORTFOLIOAPPROVAL &&
        !this.state.shouldShowApprovePortfolio
      ) {
        return "";
      }
      if (
        type === contentTypes.SUBMISSION_REVIEW &&
        !this.state.shouldShowsubmissionWindow
      ) {
        return "";
      }
      return (
        <article
          key={index}
          style={
            item && item.position
              ? renderCardStyle(item, device)
              : { gridColumn: "span 3" }
          }
        >
          {type == articleTypes.DEALSTATUSBAR ? (
            <ErrorBoundary>
              <M_DealStatusBar content={content} />
            </ErrorBoundary>
          ) : (
            ""
          )}
          {type == articleTypes.REPORT_EDITOR ? (
            <ErrorBoundary>
              <ReportEditor />{" "}
            </ErrorBoundary>
          ) : (
            ""
          )}
          {type.toLowerCase() == articleTypes.ESG ? (
            <ErrorBoundary>
              <EsgHeader />{" "}
            </ErrorBoundary>
          ) : (
            ""
          )}
          {type.toLowerCase() == articleTypes.ESGRESPONSE ? (
            <ErrorBoundary>
              <EsgResponsePage />{" "}
            </ErrorBoundary>
          ) : (
            ""
          )}
          {type == articleTypes.EXCEL_EDITOR ? (
            <ErrorBoundary>
              <ExcelTemplate
                isExcelTemplateDownload={this.props.isExcelTemplateDownload}
                handleClosePopup={this.props.handleClosePopup}
              />
            </ErrorBoundary>
          ) : (
            ""
          )}
          {type == articleTypes.KPI_CALL_BOX ? (
            <ErrorBoundary>
              <KpiCallBox item={item} />
            </ErrorBoundary>
          ) : (
            ""
          )}
          {type == articleTypes.KPI_SQL_BOX ? (
            <ErrorBoundary>
              <KpiSqlBox item={item} />
            </ErrorBoundary>
          ) : (
            ""
          )}
          {type == articleTypes.PIPE_LINE_FILTER ? (
            <ErrorBoundary>
              <PipeLineFilter
                item={item}
                pipeLineDealsControll={this.pipeLineDealsControll}
              />
            </ErrorBoundary>
          ) : (
            ""
          )}
          {type == articleTypes.DEALS_PIPE_LINE ? (
            <ErrorBoundary>
              <O_Pipeline
                item={item}
                dealData={this.state.pipelineData}
                imageData={this.state.pipelineImages}
              />
            </ErrorBoundary>
          ) : (
            ""
          )}
          {type == articleTypes.CONSENTREQUEST ? (
            <ErrorBoundary>
              <O_ConsentRequest
                title={title ? title : ""}
                content={content}
                getCurrentPage={this.props.getCurrentPage}
              />
            </ErrorBoundary>
          ) : (
            ""
          )}
          {type != articleTypes.KPI_CALL_BOX &&
          type != articleTypes.KPI_SQL_BOX &&
          type != articleTypes.DEALS_PIPE_LINE &&
          type != articleTypes.PIPE_LINE_FILTER &&
          type != articleTypes.REPORT_EDITOR &&
          type != articleTypes.EXCEL_EDITOR &&
          type != articleTypes.DEALSTATUSBAR &&
          type != articleTypes.ESG &&
          type != articleTypes.ESG_RESPONSE &&
          type != articleTypes.CONSENTREQUEST ? (
            type != "customcard" ? (
              <A_Card
                currentPage={
                  this.props &&
                  this.props.currentPage &&
                  this.props.currentPage.data
                }
                elevation={2}
                width={50}
                header={{
                  title: title ? title : "",
                  boldTitle: boldTitle ? boldTitle : "",
                  chartFilter: chartFilter ? chartFilter : "",
                  addFiles: addFiles ? addFiles : "",
                  showPic: showPic ? showPic : false,
                  show,
                  isReadOnly,
                  // zoom: toolbar && toolbar.zoom ? toolbar.zoom : false,
                  // this feature is not require now, but i am keeping it commented as it may require in future
                  secondaryMenu: false,
                  optionAdd: this.state.optionOnAdd,
                  addParameterOption:
                    item &&
                    item.header &&
                    item.header.toolbar &&
                    item.header.toolbar.optionOnAdd
                      ? item.header.toolbar.optionOnAdd.parameters
                      : {},
                  callBack: this.handleClickOptionAdd,
                  add:
                    toolbar && toolbar.add && toolbar.add.show
                      ? toolbar.add.show
                        ? isReadOnly !== true
                        : false
                      : false,
                  optionOnAdd:
                    toolbar && toolbar.optionOnAdd && toolbar.optionOnAdd.show
                      ? toolbar.optionOnAdd.show
                      : false,
                  edit:
                    toolbar && toolbar.edit && toolbar.edit.show
                      ? toolbar.edit.show
                        ? isReadOnly !== true
                        : false
                      : false,
                  export:
                    toolbar && toolbar.export && toolbar.export.show
                      ? toolbar.export.show
                      : false,
                }}
                action={
                  item.footer && item.footer.content
                    ? handleFooter(item.footer)
                    : ""
                }
                ApproveButtons={
                  item.footer && item.footer.approvalButtons === true
                    ? item.footer.approvalButtons
                    : ""
                }
                content={
                  <React.Fragment>
                    {content
                      ? showContentConditonal(type, content, item, chartFilter)
                      : item.fallbackMsg}
                  </React.Fragment>
                }
                handleZoom={() => this.handleZoom(toolbar, item)}
                handleEdit={() => this.handleEdit(toolbar, item, content)}
                handleAdd={() => this.handleAdd(toolbar, item, content)}
                handleOptionOnAdd={() =>
                  this.handleOptionOnAdd(toolbar, item, content)
                }
                handleDeleteOnAdd={() =>
                  this.handleDeleteOnAdd(toolbar, item, content)
                }
                handleExport={() => this.handleExport(toolbar, item, content)}
                handleClickOptionAdd={this.handleClickOptionAdd}
              />
            ) : (
              <ErrorBoundary>
                <M_CustomCards
                  content={content}
                  item={item}
                  stateObj={this.state}
                />
              </ErrorBoundary>
            )
          ) : (
            ""
          )}
        </article>
      );
    };

    const handleFooter = (footer) => {
      if (footer.type === externalTypes.BUTTON) {
        return footer.content.map((button, index) => {
          return <A_Button {...button} key={index} />;
        });
      } else {
        return footer.content;
      }
    };
    return (
      <>
        <article className="page-content-inner">
          {this.props.cards && this.props.cards.length > 0
            ? this.props.cards.map((item, index) => {
                {
                  return getArticle(
                    item,
                    this.state.device,
                    index,
                    this.props.readOnlyTemplate
                  );
                }
              }, this)
            : ""}
        </article>

        <A_SimpleDialog
          open={this.state.openCardZoom}
          //title="Dialog Title"
          fullScreen={false}
          fullWidth={true}
          maxWidth={deviceTypes.MD}
          dialogContent={
            this.state.cardProps ? (
              <A_Card
                elevation={2}
                width={50}
                header={{
                  title: this.state.cardProps.header.title
                    ? this.state.cardProps.header.title
                    : "",
                  zoom: false,
                  secondaryMenu: false,
                  add: false,
                  edit: false,
                  export: false,
                }}
                content={
                  <React.Fragment>
                    {this.state.cardProps.content.content ? (
                      this.state.cardProps.content.type ==
                      externalTypes.TEXT ? (
                        this.state.cardProps.content.content
                      ) : (
                        <img src={InlineRecords} />
                      )
                    ) : (
                      this.state.cardProps.fallbackMsg
                    )}
                  </React.Fragment>
                }
              />
            ) : (
              ""
            )
          }
          dialogActions={
            <A_Button
              label="Close"
              size="small"
              onClick={() => this.handleZoom("close")}
            />
          }
        />
        {this.state.openEditForm && (
          <ErrorBoundary>
            <M_ManageEntityForm
              extraParams={this.state.extraEditParams}
              getViewData={
                this.state.isIRCEdit
                  ? this.props.managePage &&
                    this.props.managePage.data &&
                    this.props.managePage.data.data
                    ? this.props.managePage.data.data
                    : ""
                  : this.props.currentPage.data
              }
              crmData={
                this.state.isIRCEdit
                  ? this.props.managePage &&
                    this.props.managePage.data &&
                    this.props.managePage.data.data
                    ? this.props.managePage.data.data
                    : ""
                  : this.props.managePage &&
                    this.props.managePage.data &&
                    this.props.managePage.data.template &&
                    this.props.managePage.data.template.templateType &&
                    this.props.managePage.data.template.templateType ==
                      "SQL form"
                  ? this.props.managePage.data.data
                  : this.props.currentPage.data
              }
              isEditable={true}
              refresh={false}
              entity={this.state.paramEntity}
              addEdge={this.state.addEdge}
              open={this.state.openEditForm}
              onClose={() => this.handleCloseForm()}
            />
          </ErrorBoundary>
        )}
        {this.state.openAddForm && (
          <ErrorBoundary>
            <M_ManageEntityForm
              extraParams={this.state.extraAddParams}
              showPopup={true}
              refresh={false}
              templateId={this.state.templateId}
              //crmData={this.props.currentPage.data}
              entity={this.state.paramEntity}
              open={this.state.openAddForm}
              onClose={() => this.handleCloseForm()}
            />
          </ErrorBoundary>
        )}
      </>
    );
  }
}

Layout.propTypes = {
  currentPage: PropTypes.object,
  params: PropTypes.any,
  cards: PropTypes.any,
  currentUser: PropTypes.object,
  handleClosePopup: PropTypes.any,
  isExcelTemplateDownload: PropTypes.any,
  managePage: PropTypes.object,
  resultData: PropTypes.object,
  onChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  parameters: PropTypes.any,
  configurations: PropTypes.any,
  data: PropTypes.any,
  handleAddButtonClick: PropTypes.func,
  handleClickableCell: PropTypes.func,
  handleDeleteButtonClick: PropTypes.func,
  handleEditButtonClick: PropTypes.func,
  resetManagePage: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    prevEntity: state.previousEntity.prevEntity,
    currentPage: state.currentPage,
    entityRecord: state.entityRecord,
    managePage: state.managePage,
    currentUser: _.get(state, "oidc.user.profile", {}),
    toolbarSelections: state.manageToolbarSelections,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetManagePage: () => dispatch(resetManagePage()),
    resetFiltersValue: () => dispatch(resetFiltersValue()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
