// TODO- as this file has multiple lint issues so skip this file for lint velidation
import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as httpCall from "../../../../../../../Utils/apis/apis";
import M_GraphChart from "./GraphCharts/M_GraphChart";
import M_TableChart from "./M_TableCharts/M_TableCharts";
import { invoice } from "./ChartCardHelper";
import Skeleton from "@material-ui/lab/Skeleton";
import ErrorBoundary from "../../../../../Molecules/Common/error/ErrorBoundary";
import * as commonFunctions from "../../../../../../../Utils/Helpers/common";
import { useParams } from "react-router";
import {
  verifyValidChartRequest,
  hasPageFilter,
  toolbarFilterData,
} from "./M_ChartCard.Helper";
import M_CardFilter from "../../../../../atoms/Inputs/Selects/M_CardFilter";
import { FromEpochFormat } from "../../../../../../../Utils/Helpers/dateUtils";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import O_Pdfchart from "../../../../../Organisms/Common/pdfchart/O_Pdfchart";
import GetAppIcon from "@material-ui/icons/GetApp";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ErrorMsg,
  filterRange,
} from "../../../../../../../Utils/config/config";
import { epochToDateFormatHelper } from "../../../../../../../Utils/Helpers/dateUtils";
import M_Footernotes from "../../../../Chart/Footernotes/M_Footernotes";
import Axios from "axios";
import { managePageActionTypes } from "../../../../../../../store/actions/actionTypes";
import { executeJSString } from "../../../../../Pages/Common/Layouts/layout.Helper";
import { getObjectFromJSONString } from "../../../Navigations/AddEntity/M_ManageEntityForm.helper";
import { portfolioapproval } from "../../../PageHeaders/Toolbar/M_ToolbarFilterhelper";
import {
  manageFilterValue,
  resetFiltersValue,
  updateFilterValue,
} from "../../../../../../../store/actions/manageFilterValueAction";
import { handleJSON } from "./M_ChartCard.Helper";
// TODO Note:Some variables are currently unused and will be removed in the future. Thorough testing is essential before removal.
const useStyles = makeStyles(() => ({
  downloadBtn: {
    position: "absolute",
    right: "60px",
    cursor: "pointer",
    zIndex: "9999999",
  },
  btnEndIcon: {
    color: "#1976D2",
    width: "21px !important",
    height: "21px !important",
    marginTop: "0.3em",
    position: "fixed",
  },
  btnEndIconswitch: {
    color: "#1976D2",
    width: "21px !important",
    height: "21px !important",
    marginTop: "-0.87em",
    position: "fixed",
  },
  circularbtn: {
    color: "#1976D2",
    width: "21px !important",
    height: "21px !important",
    marginTop: "-1.4em",
    position: "fixed",
  },
}));

//TODO - M_ChartCard renders two times or multiple for single chart, need to fix it: It causes multiple API calls
const M_ChartCard = React.memo(function M_ChartCard(props) {
  const classes = useStyles();
  const [chart, setChart] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [filterData, setFilterData] = useState(undefined);
  const { crmId, pageUrl, templateId } = useParams();
  const pageFilters = props.toolbarSelections.filters;
  const chartFilterState = props.chartFilter;
  const [chartLoading, setChartLoading] = useState(false);
  const templateFilterData = toolbarFilterData(props, templateId);
  const [prevTemplateFilterID, setPrevTemplateFilterID] = useState(
    localStorage.getItem(portfolioapproval.TEMPLATEFILTERID) || ""
  );
  const [prevFilterValue, setPrevFilterValue] = useState(
    localStorage.getItem(portfolioapproval.FILTERVALUE)
  );
  const [pathName, setPathName] = useState(
    localStorage.getItem(portfolioapproval.CURRENTPATHNAME)
      ? localStorage.getItem(portfolioapproval.CURRENTPATHNAME)
      : ""
  );
  const [newData, setNewData] = useState(undefined);
  const templateFilterID = localStorage.getItem(portfolioapproval.TEMPLATEID);
  const filterValue = localStorage.getItem(portfolioapproval.FILTERVALUE);
  const dispatch = useDispatch();
  const loggedInUserEmail = useSelector(
    (state) =>
      state.oidc.user &&
      state.oidc.user.profile &&
      state.oidc.user.profile.email
  );
  const currentDealid =
    props &&
    props.currentPage &&
    props.currentPage.data &&
    props.currentPage.data.Deal &&
    props.currentPage.data.Deal &&
    JSON.parse(
      props &&
        props.currentPage &&
        props.currentPage.data &&
        props.currentPage.data.Deal &&
        props.currentPage.data.Deal
    )[0].id;
  useEffect(() => {
    var pathname = window.location.pathname;
    var parts = pathname.split("/");
    if (parts.length > 1) {
      localStorage.setItem(portfolioapproval.CURRENTPATHNAME, parts[1]);
    }
    setPathName(localStorage.getItem(portfolioapproval.CURRENTPATHNAME));
  }, [templateFilterID]);

  useEffect(() => {
    var pathname = window.location.pathname;
    var parts = pathname.split("/");
    if (pathName != parts[1] && pathName != "") {
      dispatch(resetFiltersValue());
    }
  }, [pathName]);
  useEffect(() => {
    if (pageUrl === invoice.INBOUND_INVOICE) {
      props.content.content.sourceId = currentDealid;
      props.content.parameters.dealId = currentDealid;
      props.content.parameters.invoiceId = crmId;
    }
  }, []);
  useEffect(() => {
    //setChart(sampleGraphChart);
    if (props.content && props.content.parameters && crmId) {
      for (const key in props.content.parameters) {
        if (props.content.parameters[key] == "=crmId") {
          props.content.parameters[key] = crmId;
        } else if (props.content.parameters[key] == "=currentDate") {
          let dateObj = new Date();
          let day = dateObj.getDate().toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          });
          let month = (dateObj.getMonth() + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          });
          let dateValue = `${day}/${month}/${dateObj.getFullYear()}`;
          props.content.parameters[key] = dateValue;
        } else if (props.content.parameters[key] == "=lastYearDate") {
          let dateObj = new Date();
          // to get one year back date from now including current date
          dateObj.setDate(dateObj.getDate() + 1);
          dateObj.setFullYear(dateObj.getFullYear() - 1);
          // convert single digit number to double digits
          let day = dateObj.getDate().toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          });
          let month = (dateObj.getMonth() + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          });
          let dateValue = `${day}/${month}/${dateObj.getFullYear()}`;
          props.content.parameters[key] = dateValue;
        }
      }
    }
    if (
      props.content &&
      props.content.content &&
      props.content.content.sourceId &&
      crmId
    ) {
      if (props.content.content.sourceId == "=crmId") {
        props.content.content.sourceId = crmId;
      }
    }
    let mainDataSet = props.currentPage.data;
    let parameterKeys = props.content.parameters;
    for (let i in parameterKeys) {
      if (
        typeof parameterKeys[i] == "string" &&
        parameterKeys[i].charAt(0) === "="
      ) {
        parameterKeys[i] = parameterKeys[i].substring(1);
        if (mainDataSet && parameterKeys[i] in mainDataSet) {
          parameterKeys[i] = mainDataSet[parameterKeys[i]];
        } else {
          parameterKeys[i] = "";
        }
      }
    }
    if (templateFilterData.length > 0) {
      if (templateFilterData.length === pageFilters.length) {
        getChart(props.content, true);
      }
    } else {
      getChart(props.content, true);
    }
  }, [pageFilters]);
  useEffect(() => {
    var templateIdExist = false;
    for (let item in props.filters) {
      var id = item.split("_");
      item = id[0];
      if (item === templateFilterID) {
        templateIdExist = true;
      }
    }
    if (
      (templateFilterID !== prevTemplateFilterID ||
        filterValue != prevFilterValue) &&
      templateIdExist != true
    ) {
      props.toolbarSelections.filters.forEach((item) => {
        localStorage.setItem(portfolioapproval.FILTERVALUE, item.value);
        dispatch(
          manageFilterValue(templateFilterID, item.filterName, item.value)
        );
      });
    } else if (templateIdExist == true) {
      props.toolbarSelections.filters.forEach((item) => {
        localStorage.setItem(portfolioapproval.FILTERVALUE, item.value);
        dispatch(
          updateFilterValue(templateFilterID, item.filterName, item.value)
        );
      });
    }
    setPrevTemplateFilterID(templateFilterID);
    setPrevFilterValue(filterValue);
  }, [pageFilters]);
  const callback = () => {
    getChart(props.content, true, true);
  };
  useEffect(() => {
    return () => {
      // Cancel the request if the component is unmounted
      if (typeof cancelToken !== "undefined") {
        cancelToken.cancel("Operation canceled due to component unmount.");
      }
    };
  }, []);
  // useEffect(() => {
  //   getChart(props.content, true);
  // }, [pageFilters]);

  let cancelToken;
  const getChart = async (chartRequest, filtersChanged, skipValidation) => {
    if (
      chartRequest &&
      chartRequest.parameters &&
      chartRequest.parameters.hasOwnProperty("loggedInUserMailId")
    ) {
      chartRequest.parameters["loggedInUserMailId"] = loggedInUserEmail;
    }
    if (typeof cancelToken !== "undefined") {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = Axios.CancelToken.source();

    if (!chart || filtersChanged) {
      let url = "/ChartData/getchartdata";
      let requestPayload = JSON.parse(JSON.stringify(chartRequest));

      if (pageFilters && hasPageFilter(props.currentPage)) {
        //Add updated params to chart request
        pageFilters.forEach((pageFilter) => {
          if (
            pageFilter.type &&
            pageFilter.type === filterRange.RANGE &&
            pageFilter.to.toLowerCase() !== filterRange.ALL
          ) {
            if (pageFilter.toDate) {
              requestPayload.parameters[pageFilter.toDate] = pageFilter.to;
            } else {
              requestPayload.parameters["to"] = pageFilter.to;
            }
            // requestPayload.parameters["filterOnDateField"] =
            //   pageFilter && pageFilter.filterOnDateField
            //     ? pageFilter.filterOnDateField
            //     : undefined;
          }
          //Updating Filter Values
          const tempIdVal = `${templateFilterID}_${pageFilter.filterName}`;
          var filterValues = pageFilter.value;
          const filterVal = props.filters;
          requestPayload.parameters[pageFilter.filterName] =
            filterValues != filterVal[tempIdVal]
              ? filterValues
              : filterVal[tempIdVal]
              ? filterValues
              : pageFilter.value;
          for (let item in props.filters) {
            if (item === tempIdVal) {
              filterValues = props.filters[item];
              localStorage.setItem(portfolioapproval.FILTERVALUE, filterValues);
            }
          }
        });
      }
      if (!verifyValidChartRequest(requestPayload) && skipValidation !== true) {
        return;
      }
      setIsLoading(true);
      try {
        let loggedInUser = JSON.parse(localStorage.getItem("contact_crm"));
        if (
          Boolean(props.isPrivateTemplate) &&
          props.loggenInUser &&
          props.loggenInUser.data &&
          props.loggenInUser.data.length == 0 &&
          !loggedInUser
        ) {
          setIsLoading(false);
          setIsError(false);
          setChart({
            chartConfiguration: { type: "table" },
            data: {},
          });
        } else {
          const result = await httpCall.httpPost(url, requestPayload, {
            cancelToken: cancelToken.token,
          });
          if (Axios.isCancel(result)) {
            console.log("Request canceled:", result.message);
            return;
          }
          if ("message" in result) {
            setIsError(result);
          } else {
            setIsError(false);
            setChart(result);
            setNewData(result);
          }
        }
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("Error fetching chart data:", error);
          setIsError(true);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };
  const handleRefreshChart = (chartRequest) => {
    setChart(undefined);
    getChart(chartRequest);
  };
  const handleFilterChange = (key, value) => {
    props.content.parameters[key] = value;
    setChart(undefined);
    getChart(props.content);
  };

  let array = [];
  const handleCardLevelFilterData = async (obj) => {
    if (obj !== null) {
      array.push(obj);

      if (localStorage.getItem(obj.id)) {
        localStorage.setItem(obj.id, JSON.stringify(obj));
      } else {
        localStorage.setItem(obj.id, JSON.stringify(obj));
      }
      handleCardFilterChange(array);
    }
  };
  const handleCardFilterChange = async (array) => {
    if (array.length > 0) {
      setIsLoading(true);
      var dataTest = JSON.parse(JSON.stringify(chart));
      var dataTest1 = JSON.parse(JSON.stringify(dataTest));
      var currentChartFilter = "";
      var allSelecetdValues = [];
      await props.chartFilter.map((item) => {
        currentChartFilter = item;
        let indxArray = [];
        let arr = [];
        let localValue = JSON.parse(localStorage.getItem(item.id));
        if (
          item &&
          item.multiple &&
          localValue &&
          localValue.value &&
          localValue.value.length > 0
        ) {
          localValue.value.map((item) => {
            allSelecetdValues.push(item.value);
          });
        } else {
          allSelecetdValues = [];
        }
        if (localValue !== null) {
          arr.push(localValue);
          if (arr[0].value !== "") {
            if (
              dataTest1.data &&
              dataTest1.data.dimensions &&
              dataTest1.data.dimensions[0] &&
              dataTest1.data.dimensions[0] === arr[0].id
            ) {
              dataTest1.data.series.map((item, index) => {
                if (arr[0].date && arr[0].year) {
                  let date = new Date(FromEpochFormat(parseInt(item.name[0])));
                  let year = date.getFullYear();

                  if (year !== arr[0].value) {
                    indxArray.push(index);
                  }
                } else if (arr[0].date && arr[0].month) {
                  let date = new Date(item.name[0]);

                  let month = date.toLocaleString("default", {
                    month: "short",
                  });

                  if (month !== arr[0].value) {
                    indxArray.push(index);
                  }
                } else if (arr[0].date && arr[0].dateFormat) {
                  let dateFormat = epochToDateFormatHelper(
                    parseInt(item.data[index]),
                    arr[0].dateFormat
                  );

                  if (dateFormat !== arr[0].value) {
                    indxArray.push(index);
                  }
                } else {
                  if (item.name[0] !== arr[0].value) {
                    indxArray.push(index);
                  }
                }
              });
            } else {
              let indexOfProperties = dataTest1.data.categories.indexOf(
                arr[0].id
              );

              dataTest1.data.series.map((item, index) => {
                if (currentChartFilter && currentChartFilter.multiple) {
                  let date =
                    arr[0] && arr[0].value[0] && arr[0].value[0].date
                      ? arr[0].value[0].date
                      : "";
                  if (date && arr[0].value[0].year) {
                    let date = new Date(
                      FromEpochFormat(parseInt(item.data[indexOfProperties]))
                    );
                    let year = date.getFullYear();
                    let found = false;
                    if (allSelecetdValues.length > 0) {
                      for (let i = 0; i < allSelecetdValues.length; i++) {
                        if (allSelecetdValues[i] == year) {
                          found = true;
                          break;
                        }
                      }
                      if (!found) {
                        indxArray.push(index);
                      }
                    }
                  } else if (date && arr[0].value[0].month) {
                    let date = new Date(
                      FromEpochFormat(parseInt(item.data[indexOfProperties]))
                    );
                    let month = date.toLocaleString("default", {
                      month: "short",
                    });
                    let found = false;
                    if (allSelecetdValues.length > 0) {
                      for (let i = 0; i < allSelecetdValues.length; i++) {
                        if (allSelecetdValues[i] == month) {
                          found = true;
                          break;
                        }
                      }
                      if (!found) {
                        indxArray.push(index);
                      }
                    }
                  } else if (date && arr[0].value[0].dateFormat) {
                    let dateFormat = epochToDateFormatHelper(
                      parseInt(item.data[indexOfProperties]),
                      arr[0].value[0].dateFormat
                    );
                    let found = false;
                    if (allSelecetdValues.length > 0) {
                      for (let i = 0; i < allSelecetdValues.length; i++) {
                        if (allSelecetdValues[i] == dateFormat) {
                          found = true;
                          break;
                        }
                      }
                      if (!found) {
                        indxArray.push(index);
                      }
                    }
                  } else {
                    let found = false;
                    if (allSelecetdValues.length > 0) {
                      for (let i = 0; i < allSelecetdValues.length; i++) {
                        if (
                          allSelecetdValues[i] == item.data[indexOfProperties]
                        ) {
                          found = true;
                          break;
                        }
                      }
                      if (!found) {
                        indxArray.push(index);
                      }
                    }
                  }
                } else {
                  if (arr[0].date && arr[0].year) {
                    let date = new Date(
                      FromEpochFormat(parseInt(item.data[indexOfProperties]))
                    );
                    let year = date.getFullYear();

                    if (year !== arr[0].value) {
                      indxArray.push(index);
                    }
                  } else if (arr[0].date && arr[0].month) {
                    let date = new Date(
                      FromEpochFormat(parseInt(item.data[indexOfProperties]))
                    );
                    let month = date.toLocaleString("default", {
                      month: "short",
                    });

                    if (month !== arr[0].value) {
                      indxArray.push(index);
                    }
                  } else if (arr[0].date && arr[0].dateFormat) {
                    let dateFormat = epochToDateFormatHelper(
                      parseInt(item.data[indexOfProperties]),
                      arr[0].dateFormat
                    );

                    if (dateFormat !== arr[0].value) {
                      indxArray.push(index);
                    }
                  } else {
                    if (
                      handleJSON(item.data[indexOfProperties]) !==
                      handleJSON(arr[0].value)
                    ) {
                      indxArray.push(index);
                    }
                  }
                }
              });
            }

            for (var i = indxArray.length - 1; i >= 0; i--) {
              dataTest1.data.series.splice(indxArray[i], 1);
            }
          }
        }
      });

      setFilterData(dataTest1);
      setIsLoading(false);
    }
  };

  const handleExcelDownload = async () => {
    setChartLoading(true);
    let config = commonFunctions.getHttpConfig();
    let convertedFormData = [
      {
        chartId: props.content.chartId || null,
        parameters: props.content.parameters || {},
        content: props.content.content,
        fileName: "Report",
        worksheetName: props.templateTitle || "",
      },
    ];
    const response = await httpCall.httpPost(
      `/ChartData/download-excel`,
      convertedFormData,
      {
        ...config,
        responseType: "blob",
      }
    );
    let cardTitle =
      props.chart && props.chart.chartConfiguration
        ? props.chart.chartConfiguration.chartName + ".xlsx"
        : "Report.xlsx";
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", cardTitle);
    link.click();
    setChartLoading(false);
  };
  const showFiltersOrNot = (data) => {
    try {
      let response = data.content && data.content.hideCardLevelFilters;
      if (typeof response == "boolean") {
        return !response;
      } else {
        return true;
      }
    } catch {
      return true;
    }
  };
  return (
    <>
      <div
        style={{
          height: "auto",
          minHeight: `${props.Height}`,
        }}
      >
        {!isError &&
          chart &&
          chart.chartConfiguration &&
          (chart.chartConfiguration.type === "graph" ||
            chart.chartConfiguration.type === "table") &&
          (props.content === undefined ||
            props.content.type === undefined ||
            props.content.type.toLowerCase() !== "customchart") && (
            <ErrorBoundary>
              <Box style={{ position: "relative" }}>
                {chartFilterState && showFiltersOrNot(props.content) ? (
                  <M_CardFilter
                    chartFilter={props.chartFilter}
                    chart={chart}
                    handleCardLevelFilterData={handleCardLevelFilterData}
                    newData={newData}
                  />
                ) : (
                  ""
                )}
              </Box>
            </ErrorBoundary>
          )}

        {!isError &&
          chart &&
          chart.chartConfiguration &&
          props.content &&
          props.content.type &&
          props.content.type.toLowerCase() === "customchart" && (
            <ErrorBoundary>
              <Box>
                {chartFilterState && showFiltersOrNot(props.content) ? (
                  <M_CardFilter
                    chartFilter={props.chartFilter}
                    chart={chart}
                    handleCardLevelFilterData={handleCardLevelFilterData}
                    newData={newData}
                  />
                ) : (
                  ""
                )}
              </Box>
            </ErrorBoundary>
          )}
        {isLoading && (
          <>
            <Skeleton variant="text" width={80} height={80} />
            <Skeleton variant="text" width={"100%"} height={80} />
            <Skeleton variant="rect" width={"100%"} height={118} />
          </>
        )}
        {isError && (
          <div>
            <h4>{ErrorMsg.ERROR}</h4>
            <p> Error : {isError.message}</p>
            <h5>{ErrorMsg.MESSAGE}</h5>
          </div>
        )}
        {!isLoading &&
          !isError &&
          chart &&
          chart.chartConfiguration &&
          chart.chartConfiguration.type === "graph" &&
          (props.content === undefined ||
            props.content.type === undefined ||
            props.content.type.toLowerCase() !== "customchart") && (
            <ErrorBoundary>
              <M_GraphChart
                chartFilter={chartFilterState}
                chart={filterData ? filterData : chart}
                originalData={
                  chart && chart.data && JSON.parse(JSON.stringify(chart.data))
                }
                chartRequest={props.content}
                refreshChart={handleRefreshChart}
              />
            </ErrorBoundary>
          )}
        {!isLoading &&
          !isError &&
          chart &&
          chart.chartConfiguration &&
          chart.chartConfiguration.type === "table" &&
          (props.content === undefined ||
            props.content.type === undefined ||
            props.content.type.toLowerCase() !== "customchart") && (
            <ErrorBoundary>
              {/* <Box>{props.chartFilter ? chartFilter(props.chartFilter) : ""}</Box> */}

              <Box style={{ position: "relative" }}>
                <>
                  {chart &&
                    chart.chartConfiguration &&
                    chart.chartConfiguration.configuration &&
                    JSON.parse(chart.chartConfiguration.configuration)
                      .popUpDownloadBtn && (
                      <div className={classes.downloadBtn}>
                        {chartLoading ? (
                          <CircularProgress
                            className={
                              props.header != "" && props.header
                                ? classes.btnEndIcon
                                : classes.circularbtn
                            }
                          />
                        ) : (
                          <GetAppIcon
                            onClick={handleExcelDownload}
                            className={
                              props.header != "" && props.header
                                ? classes.btnEndIcon
                                : classes.btnEndIconswitch
                            }
                          />
                        )}
                      </div>
                    )}
                </>
              </Box>
              <M_TableChart
                header={props.header ? props.header : null}
                chartFilter={chartFilterState}
                chart={filterData ? filterData : chart}
                callback={callback}
                originalData={
                  chart && chart.data && JSON.parse(JSON.stringify(chart.data))
                }
                crmData={props.currentPage.data}
                handleFilterChange={handleFilterChange}
                parameters={
                  props.content && props.content.parameters
                    ? props.content.parameters
                    : {}
                }
              />
            </ErrorBoundary>
          )}
        {!isLoading &&
          !isError &&
          chart &&
          chart.chartConfiguration &&
          props.content &&
          props.content.type &&
          props.content.type.toLowerCase() === "customchart" && (
            <ErrorBoundary>
              <O_Pdfchart
                chartFilter={chartFilterState}
                chart={filterData ? filterData : chart}
                originalData={
                  chart && chart.data && JSON.parse(JSON.stringify(chart.data))
                }
                crmData={props.currentPage.data}
                handleFilterChange={handleFilterChange}
                parameters={
                  props.content && props.content.parameters
                    ? props.content.parameters
                    : {}
                }
              />
            </ErrorBoundary>
          )}
      </div>
      <div>
        {chart &&
          chart.chartConfiguration &&
          chart.chartConfiguration.configuration &&
          JSON.parse(chart.chartConfiguration.configuration).footnotes &&
          (executeJSString(
            getObjectFromJSONString(
              getObjectFromJSONString(
                chart,
                ".chartConfiguration.configuration"
              ),
              ".readOnlyTemplate"
            ),
            getObjectFromJSONString(props, ".currentPage.data")
          ) != true ? (
            <M_Footernotes chartData={chart} />
          ) : chart &&
            chart.chartConfiguration &&
            chart.chartConfiguration.footnotes ? (
            chart.chartConfiguration.footnotes
          ) : (
            ""
          ))}
      </div>
    </>
  );
});
M_ChartCard.defaultProps = {
  content: {},
  templateTitle: "",
  excelDownload: false,
};
M_ChartCard.propTypes = {
  toolbarSelections: PropTypes.any,
  chartFilter: PropTypes.any,
  content: PropTypes.any,
  currentPage: PropTypes.any,
  templateTitle: PropTypes.any,
  chartConfiguration: PropTypes.any,
  chart: PropTypes.any,
  Height: PropTypes.any,
  header: PropTypes.any,
  filters: PropTypes.any,
  loggenInUser: PropTypes.any,
  isPrivateTemplate: PropTypes.any,
};
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    toolbarSelections: state.manageToolbarSelections,
    filters: state.manageFilterValueReducer.filters,
    loggenInUser: state.getLoggedInUserReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(managePageActionTypes, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  undefined
)(M_ChartCard);
