import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
const useStyles = makeStyles(() => ({
  add: {
    width: "44%",
  },
  dangerButton: {
    backgroundColor: "#dc3545",
    color: "white",
    "&:hover": {
      backgroundColor: "#ba202e",
    },
  },
}));
const Comment = () => {
  const [comments, setcomments] = React.useState("");
  const [btnStatus, setBtnStatus] = useState("");
  const classes = useStyles();
  const handleChange = (event) => {
    setcomments(event.target.value);
  };

  const handleBtnStatus = (value) => {
    setBtnStatus(value);
  };
  console.log("gfr", comments);
  return (
    <div>
      <InputLabel>
        <div className="">
          <TextField
            id="standard-basic"
            label="Comments:"
            onChange={handleChange}
            className={classes.add}
            value={comments}
          ></TextField>
        </div>
      </InputLabel>
      <div style={{ marginTop: 20 }}>
        <Button
          style={{ marginRight: 10 }}
          variant="contained"
          color="primary"
          onClick={() => handleBtnStatus("Approved")}
        >
          Approved
        </Button>
        <Button
          style={{ marginRight: 10 }}
          className={classes.dangerButton}
          variant="contained"
          color="primary"
          onClick={() => handleBtnStatus("Rejected")}
        >
          Rejected
        </Button>
        <Button
          style={{ marginRight: 10 }}
          variant="contained"
          color="primary"
          onClick={() => handleBtnStatus("Provide more information")}
        >
          Provide more information
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleBtnStatus("Counter offered")}
        >
          Counter offered
        </Button>
      </div>
    </div>
  );
};

export default Comment;
