import * as httpCall from "../../../../../../Utils/apis/apis";
import { httpPost } from "../../../../../../Utils/apis/apis";
import { ApiEndpoints } from "./ApiEndpoints";

export const getAllPillar = async () => {
  const response = await httpCall.httpGet(ApiEndpoints.GET_PILLAR);
  return response.data;
};

export const getAllSubPillar = async (pillarId) => {
  const response = await httpCall.httpGet(
    `${ApiEndpoints.GET_SUBPILLAR}${pillarId}`
  );
  return response.data;
};

export const getSubPillar = async () => {
  const response = await httpCall.httpGet(ApiEndpoints.GET_SUBPILLAR);
  return response.data;
};

export const getAllMaturityAssessment = async () => {
  const response = await httpCall.httpGet(ApiEndpoints.GET_MATURITY_ASSESSMENT);
  return response.data;
};

export const getAllPaiFramework = async () => {
  const response = await httpCall.httpGet(ApiEndpoints.GET_PAI_FRAMEWORK);
  return response.data;
};

export const getAllDataConvergenceframework = async () => {
  const response = await httpCall.httpGet(ApiEndpoints.GET_DATA_CONVERGENCE);
  return response.data;
};

export const getQuestionAnstype = async () => {
  const response = await httpCall.httpGet(ApiEndpoints.GET_QUESTION_ANS_TYPE);
  return response.data;
};

export const postAddFormQuestion = async (questionFormPayload) => {
  try {
    const response = await httpPost(
      ApiEndpoints.ADD_QUESTION,
      questionFormPayload
    );
    return response;
  } catch (err) {
    console.log("err", err);
  }
};

export const postSurveyQuestion = async (SurveyPayload) => {
  const response = await httpPost(ApiEndpoints.SURVEY_QUESTION, SurveyPayload);
  return response;
};

export const getSurveyQuestionId = async (id) => {
  const response = await httpCall.httpGet(
    `${ApiEndpoints.SURVEY_QUESTION}/${id}/questions`
  );
  return response.data;
};

export const addSurvey = async (SurveyPayload) => {
  const response = await httpPost(ApiEndpoints.ADD_SURVEY, SurveyPayload);
  return response;
};

export const getQuestionsBySurvey = async (surveyId) => {
  const response = await httpCall.httpGet(
    `${ApiEndpoints.ADD_SURVEY}/${surveyId}/questions`
  );
  return response.data;
};

export const publishSurvey = async (surveyId) => {
  const response = await httpCall.httpPut(
    `${ApiEndpoints.PUBLISH_SURVEY}/${surveyId}/publish`
  );

  return response;
};
export const closeSurvey = async (surveyId) => {
  const response = await httpCall.httpPut(
    `${ApiEndpoints.GET_SURVEY}${surveyId}/close`
  );
  return response;
};
export const getAllQuestion = async () => {
  const response = await httpCall.httpGet(ApiEndpoints.ADD_QUESTION);
  return response.data;
};

export const getBooleanTypeQuestion = async (surveyid) => {
  const response = await httpCall.httpGet(
    `${ApiEndpoints.GET_BOOLEANTYPE_QUESTION}/${surveyid}/questionanswertype/boolean`
  );
  return response.data;
};

export const getParentQuestion = async () => {
  const response = await httpCall.httpGet(ApiEndpoints.GET_PARENT_QUESTION);
  return response.data;
};

export const postSurveyQuestionMap = async (payload) => {
  const response = await httpCall.httpPost(
    ApiEndpoints.POST_SURVEY_QUESTION_MAP,
    payload
  );
  return response;
};

export const putSurveyQuestionMap = async (id, payload) => {
  const response = await httpCall.httpPut(
    `${ApiEndpoints.PUT_SURVEY_QUESTION_MAP}/${id}/questions`,
    payload
  );
  return response;
};

export const patchSurveyQuestionMap = async (payload) => {
  const response = await httpCall.httpPatch(
    ApiEndpoints.POST_SURVEY_QUESTION_MAP,
    payload
  );
  return response;
};

export const deleteSurveyQuestionMap = async (questionId, surveyId) => {
  const response = await httpCall.httpDelete(
    `${ApiEndpoints.SURVEY_QUESTION}/${surveyId}/question/${questionId}`
  );
  return response;
};

export const postUpdateFormQuestion = async (id, questionFormPayload) => {
  const response = await httpCall.httpPut(
    `${ApiEndpoints.UPDATE_QUESTION}${id}`,
    questionFormPayload
  );
  return response;
};

export const getSurvey = async () => {
  const response = await httpCall.httpGet(ApiEndpoints.GET_SURVEY);
  return response.data;
};

export const getSurveyBySurveyId = async (surveyId) => {
  const response = await httpCall.httpGet(
    `${ApiEndpoints.GET_SURVEY}${surveyId}`
  );
  return response.data;
};

export const getQuestionResponseCount = async (surveyId) => {
  const response = await httpCall.httpGet(
    `${ApiEndpoints.GET_SURVEY}${surveyId}/questionresponsecount`
  );
  return response.data;
};
export const deleteQuestion = async (deleteId) => {
  const response = await httpCall.httpDelete(
    `${ApiEndpoints.DELETE_QUESTION}${deleteId}`
  );
  return response;
};

export const getAllInvestment = async () => {
  const response = await httpCall.httpGet(ApiEndpoints.GET_INVESTMENT);
  return response.data;
};

export const getInvestmentByCrmId = async (crmId) => {
  const response = await httpCall.httpGet(
    `${ApiEndpoints.GET_INVESTMENT_CRMID}${crmId}`
  );
  // Return object
  return response.data;
};

export const getResponseApprovalByAssetId = async (assetId, surveyId) => {
  const response = await httpCall.httpGet(
    `${ApiEndpoints.GET_RESPONSE_APPROVAL_ASSETID}${assetId}/survey/${surveyId}`
  );
  return response.data;
};

export const getResponseApprovalBySurveyId = async (surveyId) => {
  const response = await httpCall.httpGet(
    `${ApiEndpoints.RESPONSE_APPROVAL_SURVEYID}${surveyId}`
  );
  return response.data;
};

export const addResponseApproval = async (surveyId, payload) => {
  const response = await httpCall.httpPost(
    `${ApiEndpoints.RESPONSE_APPROVAL_SURVEYID}${surveyId}`,
    payload
  );
  return response;
};

export const getInvestmentById = async (assetId) => {
  const response = await httpCall.httpGet(
    `${ApiEndpoints.GET_INVESTMENT}/${assetId}`
  );
  return response.data;
};

export const postResponseApproval = async (comment, investmentId, surveyId) => {
  const payload = {
    comment: comment,
    investmentId: investmentId,
    surveyId: surveyId,
  };
  const response = await httpCall.httpPost(
    `${ApiEndpoints.RESPONSE_APPROVAL}`,
    payload
  );
  return response;
};

export const addResponseToQuestion = async (payload) => {
  const response = await httpCall.httpPost(
    `${ApiEndpoints.RESPONSE_QUESTION}`,
    payload
  );
  return response;
};

export const getResponseToQuestion = async (surveyId, assetId) => {
  const response = await httpCall.httpGet(
    `${ApiEndpoints.GET_RESPONSE_QUESTION}/${surveyId}/investment/${assetId}`
  );
  return response.data;
};

export const getQuestionsJsonFromSurvey = async (surveyId) => {
  const response = await httpCall.httpGet(
    `${ApiEndpoints.GET_BOOLEANTYPE_QUESTION}/${surveyId}`
  );
  return response.data;
};

export const getPreviousPublishedSurvey = async (surveyId) => {
  const response = await httpCall.httpGet(
    `${ApiEndpoints.GET_SURVEY}${surveyId}/previous`
  );
  return response.data;
};

export const getReportData = async (surveyId, investmentId) => {
  const response = await httpCall.httpGet(
    `${ApiEndpoints.GET_REPORT_DATA}/survey/${surveyId}/investment/${investmentId}`
  );
  return response.data;
};
export const putEditExistingQuestion = async (Id, payload) => {
  const response = await httpCall.httpPut(
    `${ApiEndpoints.UPDATE_QUESTION}${Id}`,
    payload
  );
  return response;
};
export const getAllRegion = async () => {
  const response = await httpCall.httpGet(
    `${ApiEndpoints.GET_OTHER_INVESTMENT}/regions`
  );
  return response;
};

export const getAllSector = async () => {
  const response = await httpCall.httpGet(
    `${ApiEndpoints.GET_OTHER_INVESTMENT}/sectors`
  );
  return response;
};
