export const radioOptions = [
  {
    id: "Arithemtic Formula",
    value: "Arithmetic Formula",
    label: "Arithmetic Formula",
    labelPlacement: "start",
  },
  {
    id: "Comparison",
    value: "Comparison",
    label: "Comparison",
    labelPlacement: "start",
  },
  {
    id: "Carry Forward",
    value: "Carry Forward",
    label: "Carry Forward",
    labelPlacement: "start",
  },
];

export const seriesOptions = [
  { label: "Actual", value: "Actual" },
  { label: "Forecast", value: "Forecast" },
  { label: "Budget", value: "Budget" },
  { label: "Underwritten", value: "Underwritten" },
];

export const lookUpTypes = {
  ARITHMETIC_FORMULA: "Arithmetic Formula",
  ARITHMETIC_EQUATION: "Arithmetic Equation",
  COMPARISON: "Comparison",
  CARRY_FORWARD: "Carry Forward",
};

export const sourceTypes = {
  BUTTON: "button",
  DROPDOWN: "dropdown",
  STRING: "string",
};
export const logicalOperators = {
  AND: "&",
  OR: "|",
  XOR: "^",
  ONE: "{",
};

export const filterTypes = {
  ACTION: "action",
  INSERT: "INSERT",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  POSITIVE: "positive",
  NEGETIVE: "negative",
  SUBSTRACTION: "substraction",
  ADDITION: "addtion",
  TYPE: "type",
  SIGN_CONSTRAINT: "signConstraint",
  ROW_BREAK_AFTER: "rowBreakAfter",
  PREVIOUS_MONTH_USAGE: "previousMonthUsage",
  SHOW_ON_TEMPLATE: "showOnTemplate",
  LOOKUP_METRIC_FLAG: "lookupMetricFlag",
  LOOKUP_METRIC_INFO: "lookupMetricInfo",
  NAME: "name",
  EDITED: "edited",
  SORT_ORDER: "sortOrder",
  LEVEL: "level",
  IS_CALCULATED: "isCalculated",
};

export const templateEnums = {
  ID: "id",
  DATERANGEINYEAR: "dateRangeInYear",
  SHOW_TAB_AS_FIELDS: "showTabAsFields",
  INVESTMENT_ID: "investmentId",
  START_MONTH: "startMonth",
  END_MONTH: "endMonth",
  DATE_RANGE: "dateRange",
  SINGLE_YEAR: "singleYear",
  SERIES: "series",
  FROM_YEAR: "fromYear",
  INCREMENT_MONTH: "incrementMonth",
  DEAL_ID: "dealId",
  INVESTMENT_NAME: "InvestmentName",
  DISABLED: "disabled",
  REQUEST_PAYLOAD: "requestPayload",
  YEAR: "year",
};
