export const ERROR_REPORTING_MAIL = "minervasupport@equipped.ai";

export const pageConfig = {
  CRM: "CRM",
  NonCRM: "Non-CRM",
  CRMID: "0",
};

export const tagTitle = {
  MODIFIED_DATE: "modifiedDate",
};

export const dealTeam = {
  NO_EDGE_FOUND: "No edge found!",
  edgeLabel: "dealTeamMember",
};

export const dateConfig = {
  format: "default",
};
export const templateMetricsAction = {
  INSERT: "INSERT",
  DELETE: "DELETE",
  UPDATE: "UPDATE",
  NOCHANGE: "NOCHANGE",
};
export const TemplatedateRange = {
  RANGE: "range",
};
export const databaseType = {
  SQL: "SQL",
  CRM: "CRM",
  DOCUMENTDB: "DOCUMENTDB",
};
export const defaultValue = {
  scaling: 1,
};
export const checkListMasterData = {
  CREDIT: "Credit",
  PIPELINE: "PIPELINE",
};
export const nameType = {
  VIEWPRIMARYFUND: "primary fund",
  VIEWSECONDARYFUND: "secondary fund",
  VIEWTERTIARYFUND: "tertiary fund",
  CONFIGTERTIARYYFUND: "TertiaryFund",
  CONFIGSECONDARYFUND: "SecondaryFund",
  CONFIGPRIMARYFUND: "PrimaryFund",
};
export const fieldType = {
  NUMBER: "number",
  OPTIONSET: "optionset",
  HTML: "html",
  MULTISELECT: "multiselect",
  LOOKUP: "lookup",
  RADIOS: "radios",
  SECTION: "section",
  BIT: "bit",
  TABLE: "table",
  DOCUMENT: "document",
  USERNAME: "username",
  FLOAT: "float",
  INTEGER: "integer",
  MULTIPLE: "multiple",
  PERCENTAGE: "percentage",
  TEXT: "text",
  STRING: "string",
  DATE: "date",
  FINANCIAL: "financial",
  SINGLECURRENCYFIELD: "SingleCurrencyField",
};
export const excelFileName = {
  EXCELFILE: "Report",
};
export const TableChartRowKey = {
  DECIMAL_PLACE_CONFIG: "decimalPlaces",
  ROW_BREAK: "rowBreak",
  ROW_TYPE: "type",
  CLASSES: "classes",
};
export const labelName = {
  IRC_SLOTES: "ircslots",
  IRC_PORTAL: "ircportal",
  IRC_BUTTON: "Send IC Document",
  CALENDER_EVENTS: "calenderEvents",
  MEETING: "meeting",
  INVESTMENT: "investment",
  IRC_Mssg:
    " Please note: all items in the checklist must be completed before the IC document can be sent",
  PURPOSE_OTHER: "",
};
export const toolbarIRCSlots = {
  TYPE: "Project",
  EDGE_LABEL: "is_irc_of",
  LABEL: "ircportal",
  PAYLOAD_TYPE: "vertex",
  DEALWORKFLOWSTATE: "OnBoarding",
  DEALSTATUS: "priority",
  ACTION: "insert",
};
export const mathematicalSigns = {
  PLUS: "+",
  MINUS: "-",
  MULTIPLY: "*",
  DIVIDE: "/",
  LEFTBRACKET: "(",
  RIGHTBRACKET: ")",
  AND: "AND",
  OR: "OR",
  XOR: "XOR",
  ONE: "1",
};
export const coords = {
  lat: 51.4975,
  lng: -0.1357,
};
export const regex = {
  REG: /^[0-9\b]+$/,
};
export const addEdge = {
  NOTREQUIRED: "notRequired",
};
export const searchTooltip = {
  GLOBALSEARCHMSG: "Search is Case-Sensitive",
};
export const meetingPreference = {
  COMPANYNAME: "equipped.ai",
  NODATA: "No Date Available",
  NOMEETINGFOUND: "NO MEETING FOUND!",
  SELECTALLDOMAIN: "*Please select all Domain preferences",
  ALWAYSTAG: "Always tag my meetings for this Domain",
  ASKBEFORE: "Tag only this meeting",
  NEVERTAG: "Never tag meetings for this Domain",
  MEETINGSYNC: "Meeting Synchronisation Preferences for Domain",
  MEETINGS: "meetings",
};
export const dateFormat = {
  // please change the name accordingly where it is used
  meetingPreference: "YYYY-M-DD",
  "DD/MM/YYYY": "DD/MM/YYYY",
  YYYYMM: "YYYYMM",
  "MMM-YYYY": "MMM-YYYY",
  "MMM-YY": "MMM-YY",
  "MMMM YYYY": "MMMM YYYY",
  "MMM YYYY": "MMM YYYY",
  defaultDate: "DD/MM/YYYY",
  defaultDateTime: "DD/MM/YYYY HH:mm",
};
export const containerType = {
  GENERIC: "generic",
  IMAGE: "image",
};

export const dealConfig = {
  currentStatus: "Developing",
  statusPipeline: "pe-deals-workflow-config",
  statusHistory: '[{ date: "=currentDate", status: "Deal Created" }]',
  statusHistoryWithTwoValue:
    '[{ date: "=currentDate", status: "Deal Created" },{ date: "=currentDate", status: "Moved To =status" }]',
  closingDealInfo:
    "Please ensure all the documents applicable to this investment are visible below. In case LBL is applicable for the investment please check the checkbox by editing the Deal Overview section before proceeding further.",
  successClosingMsg:
    "The file has been successfully uploaded. Equipped team will share returns once processed.",
  downloadFailMsg:
    "Sorry, currently downloading/uploading can't perform. Please try after few mins.",
};

export const envName = {
  VELDCAPITAL: "veldcapital.equipped.ai",
  VELDQA: "veldqa.equipped.digital",
  VELDCAPITALMINERVA: "veldcapital.minerva.app",
  VELDQANEW: "veldqanew.equipped.digital",
  VELDDEV: "velddev.equipped.digital",
};
export const TradeApproval = {
  APPROVED: "Approved",
  PAGEURL: "gift",
  REJECT: "Reject",
};
export const killedDealStatus = {
  DEAD: "Dead",
  KILLED: "Killed",
  DEVELOPING: "Developing",
};
export const ErrorMsg = {
  ERROR: "Some error occurred.",
  MESSAGE: "Please contact support team.",
};
export const InsiderButtons = {
  CLOSEBUTTON: "closeButton",
  CONFIRMBUTTON: "confirmButton",
};
export const filterRange = {
  RANGE: "dateRange",
  ALL: "all",
};

export const dateConstants = {
  MONTHS: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
};
export const ircConfig = {
  EMAIL: "irc@equipped.ai",
  RESCHEDULE: "Reschedule Meeting",
  EMAIL_HEADER: "Add a New Email Approval",
};

export const valueText = {
  VALUE: "value",
};
export const meetingConsts = {
  ORGANISER: "organiser",
  HAS_EVENT: "has_events",
  ATTENDEDBY: "attendedBy",
  HAS_COMPANYATTENDEES: "has_companyAttendees",
  ATTENDED: "attended",
};
export const clickType = {
  EMAIL: "Email",
  CALL: "Call",
};
export const firstName = {
  FIRSTNAME: "firstName",
};
export const dealFieldsName = {
  MEZZDEBT: "Mezz Debt",
  SENIORDEBT: "Senior Debt",
  NMMLEVERED: "NMM Levered",
  GROSS_MM_LEVERED: "Gross MM levered",
  IRR_LEVERED: "IRR Levered",
  SECONDARY_FUND: "Secondary Fund",
  SECONDARY_FUND_INVESTMENT: "Secondary Fund Investment",
  TERTIARY_FUND: "Tertiary Fund",
  TERTIARY_FUND_INVESTMENT: "Tertiary Fund Investment",
};
