import React, { useState, useEffect } from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popper from "@material-ui/core/Popper";
import { Tooltip } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PrintIcon from "@material-ui/icons/Print";
import { Popover } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import GetAppIcon from "@material-ui/icons/GetApp";
import { httpPost } from "../../../../../Utils/apis/apis";
import ViewListIcon from "@material-ui/icons/ViewList";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import * as commonFunctions from "../../../../../Utils/Helpers/common";
import theme from "../../../../Themes/defaultTheme";
import A_IconButton from "../../Inputs/Buttons/A_IconButton";
import A_Divider from "../../DataDisplay/Dividers/A_Divider";
import A_Paper from "../../../Atoms/Surfaces/Papers/A_Paper";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { Box } from "@material-ui/core";
import A_ProfilePicture from "../../../Atoms/DataDisplay/ProfilePicture/A_ProfilePicture";
import { useParams } from "react-router";
import FolderIcon from "@material-ui/icons/Folder";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { excelFileName } from "../../../../../Utils/config/config";
import { renderImage } from "../../../../../Utils/Helpers/common";
import Typography from "@material-ui/core/Typography";
import { replaceSpecialCharacters } from "./A_Card.helper";
function ListItemLink(props) {
  return props.tooltip ? (
    <Tooltip title={props.tooltip} arrow>
      <ListItem button component="a" {...props} />
    </Tooltip>
  ) : (
    <ListItem button component="a" {...props} />
  );
}

ListItemLink.propTypes = {
  tooltip: PropTypes.any,
};

const useDefaultStyles = makeStyles({
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
  },
  card: {
    minHeight: "5rem",
    margin: "auto 0",
    display: "flex",
    alignItems: "center",
    flex: "0 0 100%",
  },
});
const A_Card = (props) => {
  const useStyles = makeStyles(props.cardStyle);
  const classes = useStyles();
  const user = useSelector((state) => state.oidc.user);
  const defaultStyle = useDefaultStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl_Export, setAnchorEl_Export] = React.useState(null);
  let { crmId } = useParams();
  const [image, setImage] = useState("");
  const [addOption, setAddOption] = useState(
    props.header.optionAdd || props.header.deleteAdd
  );
  const [anchorOriginY, setAnchorOriginY] = useState(0);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const idAdd = props.header.optionAdd ? "simple-popover" : undefined;
  const exportOpen = Boolean(anchorEl_Export);
  const exportId = exportOpen ? "export-popper" : undefined;
  const pageFilters =
    props.toolbarSelections && props.toolbarSelections.filters;
  const boldTitle =
    props && props.header && props.header.boldTitle
      ? props.header.boldTitle
      : "";
  useEffect(() => {
    if (props.header.showPic) {
      upload();
    }
  }, []);
  const upload = () => {
    let subdomain = new URL(window.location.href).hostname.split(".")[0];
    fetch(`${process.env.DOCUMENT_HELPER}`, {
      method: "GET",
      headers: {
        filename: "profile_" + crmId,
        type: "image",
        "file-operation": "download",
        "process-file": "yes",
        "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
        subdomain: subdomain,
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    })
      .then((response) => {
        if (response.status == 204) {
          throw Error(response.statusText);
        } else if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((success) => success.blob())
      .then((data) => {
        const imageAsUrl = URL.createObjectURL(data);
        setImage(imageAsUrl);
      });
  };

  const handleCardMenu = (event) => {
    setAnchorEl(anchorEl !== null ? null : event.currentTarget);

    if (anchorEl_Export !== null) {
      handleExportMenu(event);
    }
  };
  const handleExportMenu = (event) => {
    setAnchorEl_Export(anchorEl_Export !== null ? null : event.currentTarget);
    if (anchorEl !== null) {
      handleCardMenu(event);
    }
  };
  const handleZoom = (props) => {
    props.handleZoom(props);
  };
  const handleEdit = (props) => {
    props.handleEdit(props);
  };
  const handleAdd = (props) => {
    props.handleAdd(props);
  };
  const handleOptionOnAdd = (props) => {
    props.handleOptionOnAdd(props);
  };
  const handleAddData = (event) => {
    const iconButtonClickPositionY = event.clientY + window.scrollY + 20;
    setAddOption((addOption) => !addOption);
    setAnchorOriginY(iconButtonClickPositionY);
  };
  const handleClose = () => {
    props.handleDeleteOnAdd(props);
    setAddOption(!addOption);
  };

  const handlePdfClick = async () => {
    setAnchorEl_Export(null);
    let payload;
    let newChartId;
    let monthFilteredValue = pageFilters.find((x) => x.filterName == "month");
    let yearFilteredValue = pageFilters.find((x) => x.filterName == "year");
    let propsData = _.get(
      props,
      "content.props.children.props.children.props",
      {}
    );
    let dateRangeValue = {};
    let ToolbarFilters = {};
    let AddTollbarFilters = _.get(
      propsData,
      "CurrentCard.header.toolbar.export.AddTollbarFilters"
    );
    AddTollbarFilters &&
      pageFilters.length > 0 &&
      pageFilters.forEach((item) => {
        ToolbarFilters[item.filterName] = item.value;
        //TODO Logic needs to be taken to helper file
        if (item.type === "dateRange") {
          if (item.toDate) {
            dateRangeValue[item.toDate] = item.to;
          } else {
            dateRangeValue.to = item.to;
          }
        }
      });
    let multiTabsPayload = [];
    let params = _.get(propsData, "content.parameters", {});
    let dynmicTitleExcel =
      params && params.worksheetNameTitle ? params.worksheetNameTitle : "";
    pageFilters &&
      pageFilters.map((item) => {
        params[item.filterName && item.filterName] = item.value && item.value;
        if (item.type === "dateRange" && item.to.toLowerCase() !== "all") {
          if (item.toDate) {
            params[item.toDate] = item.to;
          } else {
            params.to = item.to;
          }
        }
      });
    propsData.tabs &&
      propsData.tabs.map((item) => {
        if (item) {
          let workSheetName = replaceSpecialCharacters(item.title);
          var payloadData = _.get(item, "content.content.chart");
          if (
            payloadData &&
            payloadData.parameters &&
            payloadData.parameters.investment &&
            payloadData.parameters.investment.toLowerCase() === "=crmid"
          ) {
            payloadData.parameters.investment = crmId;
          }
          payloadData["chartId"] = payloadData["id"];
          payloadData["fileName"] = excelFileName.EXCELFILE;
          payloadData["worksheetName"] = workSheetName;
          AddTollbarFilters
            ? (payloadData["parameters"] = {
                ...payloadData.parameters,
                ...ToolbarFilters,
                ...dateRangeValue,
                ...params,
              })
            : "";
          multiTabsPayload.push(payloadData);
        }
      });
    if (monthFilteredValue != undefined && yearFilteredValue !== undefined) {
      if (propsData.tabs) {
        multiTabsPayload.map((item) => {
          (item.parameters.month = _.get(monthFilteredValue, "value") || 0),
            (item.parameters.year = _.get(yearFilteredValue, "value") || 0);
        });
        payload = multiTabsPayload;
      } else {
        payload = [
          {
            chartId: _.get(propsData, "content.chartId") || newChartId,
            content: _.get(propsData, "content.content", {}),
            parameters: params,
            fileName: excelFileName.EXCELFILE,
            worksheetName: _.get(props, "header.title") || "excelFile.xlsx",
          },
        ];
      }
    } else if (propsData.tabs) {
      payload = multiTabsPayload;
    } else {
      payload = [
        {
          chartId: _.get(propsData, "content.chartId") || newChartId,
          content: _.get(propsData, "content.content", {}),
          parameters: params,
          fileName: excelFileName.EXCELFILE,
          worksheetName: _.get(props, "header.title") || "excelFile.xlsx",
        },
      ];
    }
    propsData && propsData.content && propsData.content.RAGIndex
      ? (payload[0].RAGIndex = propsData.content.RAGIndex)
      : "";
    let config = commonFunctions.getHttpConfig();
    const response = await httpPost(`/ChartData/download-excel`, payload, {
      ...config,
      responseType: "blob",
    });
    let cardTitle =
      props.header && props.header.title
        ? props.header.title + ".xlsx"
        : dynmicTitleExcel
        ? dynmicTitleExcel + ".xlsx"
        : "excelFile.xlsx";
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", cardTitle);
    link.click();
  };
  const dealFolder = (link) => {
    window.open(link, "_blank");
  };
  const cardTitle = () => {
    var titleHeader =
      props && props.header && props.header.title ? props.header.title : "";
    if (titleHeader.length > 0 && titleHeader[0] === "=") {
      var header = titleHeader.slice(1);

      titleHeader =
        props.currentPage.data && props.currentPage.data[header]
          ? props.currentPage.data[header]
          : "";
    }
    return titleHeader;
  };
  const picUploadAndUpdate = () => {
    upload();
  };
  return (
    <ThemeProvider theme={theme}>
      <Card
        className={classes.root}
        style={{ overflow: "visible" }}
        variant={props.variant}
        elevation={props.elevation}
      >
        {props && props.header && !props.header.show ? (
          ""
        ) : (
          <Box className={defaultStyle.card}>
            <CardHeader
              classes={{
                title: classes.title,
              }}
              style={{ width: "100%", height: "1rem" }}
              avatar={
                props.header && props.header.showPic ? (
                  <A_ProfilePicture
                    callback={picUploadAndUpdate}
                    src={
                      image
                        ? image
                        : renderImage(
                            props &&
                              props.currentPage &&
                              props.currentPage.pageConfig &&
                              props.currentPage.pageConfig.defaultImage
                              ? props.currentPage.pageConfig.defaultImage
                              : ""
                          )
                    }
                    {...props.header}
                  />
                ) : (
                  ""
                )
              }
              title={
                <React.Fragment>
                  {boldTitle ? (
                    <Typography variant="h6" id="tableTitle" component="div">
                      {cardTitle()}
                    </Typography>
                  ) : (
                    cardTitle()
                  )}

                  {props.header && props.header.addFiles ? (
                    <A_Button
                      label="Open Deal Folder"
                      color="primary"
                      startIcon={<FolderIcon />}
                      onClick={() =>
                        dealFolder(
                          props.currentPage && props.currentPage.folderlink
                        )
                      }
                    />
                  ) : (
                    ""
                  )}
                </React.Fragment>
              }
              action={
                <React.Fragment>
                  {props.currentPage &&
                  props.currentPage.pageConfig &&
                  props.currentPage.pageConfig.pageSecurity &&
                  (props.currentPage.pageConfig.pageSecurity.Full === true ||
                    props.currentPage.pageConfig.pageSecurity.Update ===
                      true) ? (
                    props.header.add ? (
                      <A_IconButton
                        style={{ height: "3rem", marginTop: "-12px" }}
                        aria-describedby={id}
                        icon={<AddIcon />}
                        onClick={() => handleAdd(props)}
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  <>
                    {props.currentPage &&
                    props.currentPage.pageConfig &&
                    props.currentPage.pageConfig.pageSecurity &&
                    (props.currentPage.pageConfig.pageSecurity.Full === true ||
                      props.currentPage.pageConfig.pageSecurity.Update === true)
                      ? props.header.optionOnAdd && (
                          <A_IconButton
                            style={{ height: "3rem", marginTop: "-12px" }}
                            aria-describedby={id}
                            icon={<AddIcon />}
                            onClick={(event) => {
                              handleAddData(event);
                              handleOptionOnAdd(props);
                            }}
                          />
                        )
                      : ""}
                    <Popover
                      id={idAdd}
                      open={addOption}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: anchorOriginY,
                        horizontal: "right",
                      }}
                    >
                      {props.header &&
                      props.header.addParameterOption &&
                      props.header.addParameterOption.length > 0
                        ? props.header.addParameterOption.map((item) => {
                            return (
                              <div key={item}>
                                <A_Button
                                  variant="text"
                                  label={item.label}
                                  color={"white"}
                                  //  className={classes.buttonMargin}
                                  onClick={() => {
                                    props.handleClickOptionAdd(item);
                                    props.handleDeleteOnAdd(props);
                                  }}
                                ></A_Button>
                                <A_Divider />
                              </div>
                            );
                          })
                        : ""}
                    </Popover>
                  </>
                  {props.currentPage &&
                  props.currentPage.pageConfig &&
                  props.currentPage.pageConfig.pageSecurity &&
                  (props.currentPage.pageConfig.pageSecurity.Full === true ||
                    props.currentPage.pageConfig.pageSecurity.Update ===
                      true) ? (
                    props.header.edit ? (
                      <A_IconButton
                        style={{ height: "3rem", marginTop: "-12px" }}
                        aria-describedby={id}
                        icon={<EditIcon />}
                        onClick={() => handleEdit(props)}
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {props.currentPage &&
                  props.currentPage.pageConfig &&
                  props.currentPage.pageConfig.pageSecurity &&
                  (props.currentPage.pageConfig.pageSecurity.Full === true ||
                    props.currentPage.pageConfig.pageSecurity.Update ===
                      true) ? (
                    props.header.zoom ? (
                      <A_IconButton
                        style={{ height: "3rem", marginTop: "-12px" }}
                        aria-describedby={id}
                        icon={<ZoomInIcon />}
                        onClick={() => handleZoom(props)}
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {props.header.export ? (
                    <A_IconButton
                      style={{ height: "3rem", marginTop: "-12px" }}
                      aria-describedby={id}
                      onClick={(e) => handleExportMenu(e)}
                      icon={<GetAppIcon />}
                    />
                  ) : (
                    ""
                  )}

                  <Popper
                    className={classes.paper}
                    style={{ zIndex: 1 }}
                    id={exportId}
                    open={exportOpen}
                    anchorEl={anchorEl_Export}
                    placement="bottom-end"
                  >
                    <A_Paper
                      width={20}
                      height={20}
                      content={
                        <List component="nav" aria-label="card menu">
                          <ListItemLink
                            button
                            onClick={handlePdfClick}
                            tooltip="Excel"
                          >
                            <ListItemIcon>
                              <ViewListIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Excel" />
                          </ListItemLink>

                          {/*  <A_Divider />
                         //functionality in progress
                        <ListItemLink tooltip="PDF">
                          <ListItemIcon>
                            <PictureAsPdfIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="PDF" />
                        </ListItemLink>
                        <A_Divider />
                        <ListItemLink
                          tooltip="PNG"
                          onClick={() => handleMenuClick("profile_settings")}
                        >
                          <ListItemIcon>
                            <ImageIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="PNG" />
                        </ListItemLink> */}
                        </List>
                      }
                    />
                  </Popper>

                  {props.header.secondaryMenu && (
                    <A_IconButton
                      aria-describedby={id}
                      onClick={(e) => handleCardMenu(e)}
                      icon={<MoreVertIcon />}
                    />
                  )}
                  <Popper
                    className={classes.paper}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    placement="bottom-end"
                  >
                    <A_Paper
                      width={25}
                      height={20}
                      content={
                        <List component="nav" aria-label="card menu">
                          <ListItemLink
                            onClick={props.handleClose}
                            tooltip="Print"
                          >
                            <ListItemIcon>
                              <PrintIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Print" />
                          </ListItemLink>
                          <A_Divider />
                          {/* <ListItemLink tooltip="Hide temporarily" onClick={()=>handleMenuClick('profile_settings')}>
                          <ListItemIcon>
                            <VisibilityOffIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="Hide" />
                      </ListItemLink>  */}
                          <A_Divider />
                          <ListItemLink
                            tooltip="Card information"
                            // onClick={() => handleMenuClick("profile_settings")}
                          >
                            <ListItemIcon>
                              <InfoIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Card Info" />
                          </ListItemLink>
                          <A_Divider />
                          <ListItemLink
                            tooltip="Open in editor"
                            // onClick={() => handleMenuClick("open_in_editor")}
                          >
                            <ListItemIcon>
                              <OpenInNewIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Open in editor" />
                          </ListItemLink>
                        </List>
                      }
                      size="small"
                    />
                  </Popper>
                </React.Fragment>
              }
            />
          </Box>
        )}
        <A_Divider />
        <CardContent>
          {/* <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {props.title}
          </Typography> 
          <Typography variant="p" component="div">
            {props.content}
          </Typography>*/}
          {props.content}
        </CardContent>
        {props.action && <A_Divider />}
        <CardActions>{props.action}</CardActions>
      </Card>
    </ThemeProvider>
  );
};
A_Card.defaultProps = {
  variant: "elevation",
  elevation: 3,
  header: { zoom: false },
  cardStyle: {
    root: {
      maxWidth: "100%",
      height: "100%",
    },
    title: {
      fontSize: 20,
    },
    pos: {
      marginBottom: 0,
    },
  },
};
A_Card.propTypes = {
  cardStyle: PropTypes.object,
  header: PropTypes.object,
  handleZoom: PropTypes.func,
  handleEdit: PropTypes.func,
  handleAdd: PropTypes.func,
  handleOptionOnAdd: PropTypes.func,
  handleDeleteOnAdd: PropTypes.func,
  variant: PropTypes.string,
  elevation: PropTypes.any,
  currentPage: PropTypes.object,
  handleMenuClick: PropTypes.func,
  handleClose: PropTypes.func,
  handleClickOptionAdd: PropTypes.func,
  content: PropTypes.object,
  action: PropTypes.any,
  toolbarSelections: PropTypes.any,
  ApproveButtons: PropTypes.any,
};
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    toolbarSelections: state.manageToolbarSelections,
  };
}

export default connect(mapStateToProps, undefined)(A_Card);
